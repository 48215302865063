import React from 'react';
import AuthenticationService from "../../auth/AuthenticationService";
import {toast} from 'react-toastify';
import {NavLink, withRouter} from 'react-router-dom';
import APPLICATION_CONSTANTS from "../../../config/messages";
import MenuAdministrador from "./MenuAdministrador";
import logo from '../../../logo_white.png';
import Navbar from "react-bootstrap/cjs/Navbar";
import Nav from "react-bootstrap/cjs/Nav";

class NavigationBar extends React.Component {
    handleLogout = () => {
        AuthenticationService.logout().then(() => {
            //Logout
            toast.info(APPLICATION_CONSTANTS.AUTH_LOGOUT);
            this.props.onLogout();
            //Redirige al home
            this.props.history.push('/');
        });
    };

    render() {
        const {isUserAuthenticated, userRole} = this.props;
        return (
            <div>
                <Navbar bg="primary" variant="dark" sticky="top" collapseOnSelect expand="lg">

                    <NavLink to={`${process.env.PUBLIC_URL}/`}>
                        <img src={logo} className="img-fluid" style={{width: "180px", zIndex: 99}}
                             alt={APPLICATION_CONSTANTS.APP_NAME}/>
                    </NavLink>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto ml-5">
                            <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}`}>Inicio</NavLink>
                            {!isUserAuthenticated &&
                            <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/quienes-somos`}>Quienes
                                Somos</NavLink>
                            }
                            {isUserAuthenticated &&
                            <MenuAdministrador isUserAuthenticated={isUserAuthenticated} userRole={userRole}/>
                            }
                            {!isUserAuthenticated &&
                            <NavLink className="nav-item nav-link btn btn-primary" style={{color: "white"}}
                                     to={`${process.env.PUBLIC_URL}/contrataciones`}>Contrataciónes</NavLink>
                            }
                            {!isUserAuthenticated &&

                            <NavLink className="nav-item nav-link btn btn-inverse" style={{color: "white"}}
                                     to={`${process.env.PUBLIC_URL}/login`}>Inicia Sesion</NavLink>
                            }
                            {!isUserAuthenticated &&

                            <NavLink className="nav-item nav-link btn btn-inverse" style={{color: "white"}}
                                     to={`${process.env.PUBLIC_URL}/admin`}>ADMINISTRADOR</NavLink>
                            }

                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

export default withRouter(NavigationBar);