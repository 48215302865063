import React, { Component } from 'react';

export default class FormRenta extends Component {

    constructor(props){
        super(props);
        this.props = props;
    }

    render(){
        return (
            <div className={this.props.estilo}>
                <div className="card card-body">
                    <h4>{this.props.title}</h4>
                    <form id={this.props.index} onSubmit={this.props.onSubmitRentaCallback} className="form-group">
                        <label htmlFor="cuota">Cuota:</label>
                        <input
                            className="form-control"
                            type="number"
                            name="cuota"
                            min='0'
                            id={this.props.index}
                            onChange={this.props.onChangeCallback}
                            value={this.props.cuota}/>
                        <div className="m-2">
                            <label>Deuda:</label>
                            <strong className="ml-1">{this.props.deuda}</strong>
                        </div>
                        <label htmlFor="pago">Pago:</label>
                        <input
                            className="form-control"
                            type="number"
                            name="pago"
                            min="0"
                            id={this.props.index}
                            onChange={this.props.onChangeCallback}
                            value={this.props.pago}/>
                        <div className="m-2">
                            <label>Faltó:</label>
                            <strong className="ml-1">{this.props.faltante}</strong>
                        </div>
                        <button className="btn btn-primary" type="submit">
                            Guardar
                        </button>
                    </form>
                </div>
            </div>
        );
    }

}