import React from 'react';
import CarouselIndex from "./CarouselIndex";
import {Jumbotron} from 'react-bootstrap';
import taxistripe from "../../../images/taxi-stripe.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCertificate} from '@fortawesome/free-solid-svg-icons'
import taxi from '../../../images/about1.png';
import taxi2 from '../../../images/about4.png';
import Image from "react-bootstrap/cjs/Image";

class PublicHomeIndex extends React.Component {
    render() {
        return (
            <div>
                <CarouselIndex/>
                <img className="d-block w-100" src={taxistripe} alt="Third slide"/>
                <Jumbotron fluid>
                    <div className="text-center">
                        <h2 className="display-4 ">VIAJA DE FORMA SEGURA, RÁPIDA Y ECONÓMICA</h2>
                        <p className="lead">Tu Rutina, Tu Negocio, Tu Diversión, Tu Empresa, Tu Familia</p>
                        <br className="my-4"/>

                        <a className="btn btn-danger btn-lg"  href="https://taxisqualo.com/app/cliente.apk" role="button">Descarga Nuestra Aplicación</a>
                    </div>
                </Jumbotron>

                <div className=" container-fluid">

                    <div className="row vertical-city-cab ">
                        <div className="col-md-6">
                            <div className="my-auto">
                                <p className="uk-text-a2">
                                    <Image src={taxi} fluid
                                           className="uk-text-center uk-scrollspy-init-inview uk-scrollspy-inview uk-animation-slide-left"
                                           data-uk-scrollspy="{cls:'uk-animation-slide-left', delay:600}"
                                    />
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6  ">
                            <h4 className="text-center text-light">CLIENTES & SERVICIOS</h4>
                            <p className="uk-text-a2 text-center p-mar-5">
                                Nos adaptamos a las necesidades de los clientes:
                            </p>
                                <ul style={{listStyleType: "none"}}>
                                    <li className="uk-text-a2 blocknumber blocknumber-lt blocknumber-sm ">
                                        <span className="circle da-span">
                                            <FontAwesomeIcon icon={faCertificate} size="lg"/></span>
                                        Servicio Express
                                    </li>
                                    <li className="uk-text-a2 blocknumber blocknumber-lt blocknumber-sm ">
                                        <span className="circle da-span">
                                            <FontAwesomeIcon icon={faCertificate} size="lg"/></span>
                                        Reservación de traslados a aeropuerto y central camionera
                                    </li>
                                    <li className="uk-text-a2 blocknumber blocknumber-lt blocknumber-sm ">
                                        <span className="circle da-span">
                                            <FontAwesomeIcon icon={faCertificate} size="lg"/></span>
                                        Recorrido por los principales puntos turísticos de la ciudad
                                    </li>
                                    <li className="uk-text-a2 blocknumber blocknumber-lt blocknumber-sm ">
                                        <span className="circle da-span">
                                            <FontAwesomeIcon icon={faCertificate} size="lg"/></span>
                                        Alquiler de vehículos y chofer por hora.
                                    </li>
                                    <li className="uk-text-a2 blocknumber blocknumber-lt blocknumber-sm ">
                                        <span className="circle da-span">
                                            <FontAwesomeIcon icon={faCertificate} size="lg"/></span>
                                        Servicio de mensajería
                                    </li>
                                </ul>

                            <br/>
                            <h4 className="text-center text-light p-mar-5">EMPRESAS Y NEGOCIOS</h4>
                            <p>Tenemos lo que necesitas para el transporte puntual y seguro de tu personal y
                                clientes.</p>
                            <ul style={{listStyleType: "none"}}>
                                <li className="uk-text-a2 blocknumber blocknumber-lt blocknumber-sm "><span
                                    className="circle da-span">
                                            <FontAwesomeIcon icon={faCertificate} size="lg"/></span>Sistema de vales
                                    para usuarios de nuestros servicios
                                </li>
                                <li className="uk-text-a2 blocknumber blocknumber-lt blocknumber-sm "><span
                                    className="circle da-span">
                                            <FontAwesomeIcon icon={faCertificate} size="lg"/></span> La facturación de
                                    servicios en línea
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="row bg-warning ">
                        <div className="col-md-6 my-auto text-right p-5">
                            <h1>TaxisQualo</h1>
                            <h3>Tú ciudad, tú taxi de confianza</h3>
                        </div>
                        <div className="col-md-6">
                            <div>
                                <p>
                                    <Image src={taxi2} fluid
                                           className="uk-text-center uk-scrollspy-init-inview uk-scrollspy-inview uk-animation-slide-left"
                                           data-uk-scrollspy="{cls:'uk-animation-slide-left', delay:600}"/>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default PublicHomeIndex;