import React from 'react';
import TaxisService from "./services/TaxisService";
import {toast} from 'react-toastify';
import {DEBUG_MODE} from "../../../config/api";

class TaxisFormCreate extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeHandler = this.onSelectFotoTaxi.bind(this);
        this.onChangeHandler = this.onSelectFotoSeguro.bind(this);
        this.state = {
            error: null,
            selectedFotoTaxi: null,
            selectedFotoSeguro: null,
            mytaxi: []

        };
    }

    componentDidUpdate(prevProps) {
        const {mytaxi} = this.props;
        if (mytaxi !== prevProps.mytaxi) {
            this.setState({
                mytaxi: mytaxi
            });
        }

    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (!event.target.checkValidity()) {
            if (DEBUG_MODE)
                console.warn('Invalid data!');
            toast.error('Por favor llena todos los campos');
            return;
        }
        const data = new FormData(event.target);
        if (this.state.selectedFotoTaxi != null)
            data.append('foto_taxi', this.state.selectedFotoTaxi, this.state.selectedFotoTaxi.name);
        else
            data.append('foto_taxi', null);
        if (this.state.selectedFotoSeguro != null)
            data.append('foto_seguro', this.state.selectedFotoSeguro, this.state.selectedFotoSeguro.name);
        else
            data.append('foto_seguro', null);

        if (!data.get('id_taxi')) {
            TaxisService.createTaxi(data).then((response) => {
                if (response.Error) {
                    toast.error(response.Error);
                    return;
                }
                const taxi = response.data;
                if (DEBUG_MODE)
                    console.log(taxi);
                toast.success(`Taxi creado exitosamente`);
                this.props.onCreateTaxi(taxi);
                event.target.reset();

            }).catch((response) => {
                const {error} = response;
                toast.error(`Error ${error}`);
                if (DEBUG_MODE)
                    console.error(response);
            });
        } else {
            const id_taxi = data.get('id_taxi');
            TaxisService.updateTaxi(data, id_taxi).then((response) => {
                if (response.Error) {
                    toast.error(response.Error);
                    return;
                }
                const taxi = response.data;
                if (DEBUG_MODE)
                    console.log(taxi);
                toast.success(`El Taxi ha sido editado exitosamente`);
                this.props.onUpdateTaxi(taxi);
                event.target.reset();

            }).catch((response) => {
                const {error} = response;
                toast.error(`Error ${error}`);
                if (DEBUG_MODE)
                    console.error(response);
            });
        }
    };

    onSelectFotoTaxi = event => {
        this.setState({
            selectedFotoTaxi: event.target.files[0],
            loaded: 0,
        });
        if (DEBUG_MODE)
            console.log(`{"selectedFotoTaxi": ${event.target.files[0]}`);
    };
    onSelectFotoSeguro = event => {
        this.setState({
            selectedFotoSeguro: event.target.files[0],
            loaded: 0,
        });
        if (DEBUG_MODE)
            console.log(`{"selectedFotoTaxi": ${event.target.files[0]}`);
    };

    onToDoChange(value) {
        const data = this.state.mytaxi;
        data[value['inputName']] = value['inputValue'];
        this.setState({
            mytaxi: data
        });
    }


    render() {
        const {mytaxi} = this.state;
        return (
            <div className="b-form">
                {mytaxi.id && <h2>Editar Taxi</h2>}

                {!mytaxi.id && <h2>Nuevo Taxi</h2>}
                <form id="taxiForm" onSubmit={this.handleSubmit} noValidate>
                    <div className="form-group d-none">
                        <label htmlFor="id_taxi">Id</label>
                        <input type="hidden" className="form-control" id="id_taxi" name="id_taxi" required
                               value={mytaxi.id && mytaxi.id}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="marca">Marca</label>
                        <input type="text" className="form-control" id="marca" name="marca" required
                               value={mytaxi.marca ? mytaxi.marca : ""}
                               onChange={e => this.onToDoChange({
                                   "inputName": e.target.name,
                                   "inputValue": e.target.value
                               })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="modelo">Modelo</label>
                        <input type="texto" className="form-control" id="modelo" name="modelo" required
                               value={mytaxi.modelo ? mytaxi.modelo : ""} onChange={e => this.onToDoChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="cuota">Cuota</label>
                        <input type="texto" className="form-control" id="cuota" name="cuota" required
                               value={mytaxi.cuota ? mytaxi.cuota: ""}
                               onChange={e => this.onToDoChange({
                                   "inputName": e.target.name,
                                   "inputValue": e.target.value
                               })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="no_serie">Número de Serie</label>
                        <input type="text" className="form-control" id="no_serie" name="no_serie" required
                               value={mytaxi.no_serie ? mytaxi.no_serie: ""} onChange={e => this.onToDoChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="motor">Motor</label>
                        <input type="text" className="form-control" id="motor" name="motor" required
                               value={mytaxi.motor ? mytaxi.motor: ""}
                               onChange={e => this.onToDoChange({
                                   "inputName": e.target.name,
                                   "inputValue": e.target.value
                               })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="placas">Placas</label>
                        <input type="text" className="form-control" id="placas" name="placas" required
                               value={mytaxi.placas ? mytaxi.placas: ""} onChange={e => this.onToDoChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exp_tarjeta_circulacion">Expiración de la tarjeta de circulación</label>
                        <input type="date" className="form-control" id="exp_tarjeta_circulacion"
                               name="exp_tarjeta_circulacion" required
                               value={mytaxi.exp_tarjeta_circulacion ? mytaxi.exp_tarjeta_circulacion: ""}
                               onChange={e => this.onToDoChange({
                                   "inputName": e.target.name,
                                   "inputValue": e.target.value
                               })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="comentarios">Comentarios</label>
                        <textarea className="form-control" id="comentarios" name="comentarios"
                                  onChange={e => this.onToDoChange({
                                      "inputName": e.target.name,
                                      "inputValue": e.target.value
                                  })}>{mytaxi.comentarios ? mytaxi.comentarios: ""}</textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <select className="form-control" id="status" name="status" required
                                value={mytaxi.status ? mytaxi.status: ""} onChange={e => this.onToDoChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}>
                            <option value="ACTIVO">ACTIVO</option>
                            <option value="INACTIVO">INACTIVO</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="foto_taxi">Foto de Taxi</label>
                        {mytaxi.foto_taxi &&
                        <img src={mytaxi.foto_taxi} alt="foto_taxi" className="img-fluid thumb"
                        />
                        }
                        <input type="file" className="form-control"
                               onChange={this.onSelectFotoTaxi}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="foto_seguro">Foto del Seguro</label>
                        {mytaxi.foto_taxi &&
                        <img src={mytaxi.foto_seguro} alt="foto_seguro" className="img-fluid thumb"
                        />
                        }
                        <input type="file" className="form-control"
                               onChange={this.onSelectFotoSeguro}/>
                    </div>

                    {mytaxi.id &&
                    <button type="submit" className="btn btn-primary">Editar Taxi</button>
                    }
                    {!mytaxi.id &&
                    <button type="submit" className="btn btn-primary">Registrar Taxi</button>
                    }

                </form>
            </div>
        );
    }
}

export default TaxisFormCreate;