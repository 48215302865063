import React from 'react';

import {BrowserRouter, Route, Switch,} from 'react-router-dom';
import PublicHomeIndex from "../components/public/home/PublicHomeIndex";
import LoginComponent from "../components/public/login/LoginComponent";
import UsersIndex from "../components/private/usuarios/UsersIndex";
import TaxisIndex from "../components/private/taxis/TaxisIndex";
import PrivateRoute from "./PrivateRoute";
import AuthenticationService from "../components/auth/AuthenticationService";
import NavigationBar from "../components/common/theme/NavigationBar";
import CarreraIndex from "../components/private/carreras/CarreraIndex";
import ChoferIndex from "../components/private/choferes/ChoferIndex";
import ReportesIndex from "../components/private/reportes/ReportesIndex";
import InventarioIndex from "../components/private/inventario/InventarioIndex";
import ClientIndex from "../components/private/clientes/ClientIndex";
import ContratacionesIndex from "../components/public/contrataciones/ContratacionesIndex";
import QuienesSomosIndex from "../components/public/quienes-somos/QuienesSomosIndex";
import ConfiguracionIndex from "../components/private/configuracion/ConfiguracionIndex";
import MapaIndex from "../components/private/mapas/MapaIndex";
import Rentas from "../components/private/renta/Rentas";
import Renta from '../components/private/renta/Renta';
import RentaCalendario from "../components/private/renta/RentaCalendario";
import CreaRenta from '../components/private/renta/CreaRenta';
import Aceites from '../components/private/aceites/Aceites';

class Router extends React.Component {
    state = {
        isUserAuthenticated: false,
        myRuserRoleole: null
    };

    constructor(props) {
        super(props);
        this.state.isUserAuthenticated = AuthenticationService.isUserAuthenticated();
        this.state.userRole = AuthenticationService.getRole();
    };

    onLogin = () => {
        this.setState((prevState) => ({
            isUserAuthenticated: AuthenticationService.isUserAuthenticated(),
            userRole: AuthenticationService.getRole()
        }));

    };
    onLogout = () => {
        this.setState((prevState) => ({
            isUserAuthenticated: AuthenticationService.isUserAuthenticated(),
            userRole: null
        }));
    };

    render() {
        const {isUserAuthenticated, userRole} = this.state;

        return (
            <BrowserRouter>
                <div>
                    <NavigationBar isUserAuthenticated={isUserAuthenticated} userRole={userRole}
                                   onLogout={this.onLogout}/>
                    <Switch>

                        <Route path={`${process.env.PUBLIC_URL}/login`}
                               render={(props) => <LoginComponent {...props} onLogin={this.onLogin} isUser={false}/>}/>
                        <Route path={`${process.env.PUBLIC_URL}/admin/login`}
                               render={(props) => <LoginComponent {...props} onLogin={this.onLogin} isUser={true}/>}/>
                        <Route path={`${process.env.PUBLIC_URL}/admin/`}
                               render={(props) => <LoginComponent {...props} onLogin={this.onLogin} isUser={true}/>}/>
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/clientes`} component={ClientIndex}/>
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/usuarios`} component={UsersIndex}/>
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/taxis`} component={TaxisIndex}/>
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/carreras`} component={CarreraIndex}/>
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/mapa`} component={MapaIndex}/>
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/choferes`} component={ChoferIndex}/>
                        <PrivateRoute path={`${process.env.PUBLIC_URL}inventario`} component={InventarioIndex}/>
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/reportes`} component={ReportesIndex}/>
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/configuracion`} component={ConfiguracionIndex}/>
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/rentas`} component={Rentas}/>
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/renta`} component={Renta}/>
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/calendario/:id_chofer`} component={RentaCalendario}/>
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/crearenta`} component={CreaRenta}/>
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/aceites/:id_taxi`} component={Aceites}/>
                        <Route path={`${process.env.PUBLIC_URL}/contrataciones`} component={ContratacionesIndex}/>
                        <Route path={`${process.env.PUBLIC_URL}/quienes-somos`} component={QuienesSomosIndex}/>
                        <Route component={PublicHomeIndex}/>
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}

export default Router;