import React, { Component } from 'react';

import RentaRow from './RentaRow'

export default class RentasTable extends Component {
    
    constructor(props){
        super(props);
        this.props = props;
    }

    render(){
        return (
            <div className="table-responsive">
                <table className="table">
                    <thead className="thead-dard">
                        <tr>
                            <th scope="col">Taxi Asignado</th>
                            <th scope="col">Nombre del chofer</th>
                            <th scrope="col">Deuda actual</th>
                            <th scrope="col">Deuda total</th>
                            <th scope="col">Abono de hoy</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.rentas.map(renta => <RentaRow key={renta.id} renta={renta}/> )}
                    </tbody>
                </table>
            </div>
        );
    }

}