import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons'

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer-wrapper" id="footer-wrapper">
                <div className="container">
                    <div id="footer" className="row">
                        <div className="col-md-4">
                            <p><b>TaxisQualo</b><br/>
                                Tú ciudad, tú taxi de confianza</p>

                        </div>
                        <div className="col-md-4 text-center">
                            <div>
                                <a href="#" className="btn btn-inverse social mr-2"><FontAwesomeIcon icon={faTwitter}/></a>
                                <a href="//facebook.com/113080700523163" className="btn btn-inverse social mr-2"><FontAwesomeIcon icon={faFacebook}/></a>
                                <a href="#" className="btn btn-inverse social mr-2"><FontAwesomeIcon icon={faInstagram}/></a>
                            </div>
                            <a className="totop" rel="nofollow" href="#page-top" title="Goto Top"
                               data-uk-smooth-scroll=""><i className="uk-icon-caret-up"></i></a>
                        </div>
                        <div className="col-md-4 text-right">
                            <span className="copyright">Desarrollado por KrowMx.</span>
                        </div>
                    </div>
                </div>

            </footer>
        );
    }
}

export default Footer;