import axios from "axios/index";
import {API_BASE_URL, SESSION_KEY} from "../../../../config/api";
import SessionStorageService from "../../../auth/SessionStorageService";

export default class AfinacionService {

    constructor(){        
        this.api = axios.create({
            baseURL: API_BASE_URL,
            headers: this.createHeaders()
        });
    }

    createHeaders() {

        const headers = {'Content-Type': 'application/json'};

        const userData = SessionStorageService.get(SESSION_KEY);
        if (!!userData && typeof userData.api_token === 'string') {
            headers['Authorization'] = userData['api_token'];
        }

        return headers;
    }

    formatDate = () => {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
 
    create = data => this.api.post('/afinacion', data);

    update = (id, data) => this.api.put('/afinacion/'+id, data);

    getAfinacion = id => this.api.get('/afinacion/'+id+'?fecha='+this.formatDate());

    delete = id => this.api.delete('/afinacion/'+id);

}