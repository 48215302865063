import React from 'react';
import ClientService from "./services/ClientService";
import {toast} from 'react-toastify';
import {DEBUG_MODE} from "../../../config/api";

class ClientFormCreate extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            error: null,
        };
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (!event.target.checkValidity()) {
            if (DEBUG_MODE)
                console.warn('Invalid data!');
            toast.error('Por favor llena todos los campos');
        }

        const data = new FormData(event.target);
        ClientService.createClient(data).then((response) => {
            if (response.Error) {
                toast.error(response.Error);
                return;
            }
            const client = response.data;
            if (DEBUG_MODE)
                console.log(client);
            const {name, correo} = client;
            toast.success(`Cliente ${name} creado exitosamente con el correo ${correo} `);
             this.props.onCreateClient(client);

        }).catch((response) => {
            const {error} = response;
            toast.error(`Error ${error}`);
            if (DEBUG_MODE)
                console.error(response);
        });
    };


    render() {
        return (
            <div className="b-form">
                <h2>Nuevo Cliente</h2>
                <form onSubmit={this.handleSubmit} noValidate>
                    <div className="form-group">
                        <label htmlFor="name">Nombre</label>
                        <input type="text" className="form-control" id="name" name="name" required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="correo">Correo electrónico</label>
                        <input type="email" className="form-control" id="correo" name="correo" required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" id="password" name="password" required/>
                    </div>
                    <button type="submit" className="btn btn-primary">Registrar</button>
                </form>
            </div>
        );
    }
}

export default ClientFormCreate;