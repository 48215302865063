import React from 'react';
import licencia from '../../../images/requisitos/licencia.png';
import nopenales from '../../../images/requisitos/nopenales.png';
import cv from '../../../images/requisitos/cv.png';
import antidoping from '../../../images/requisitos/antidoping.png';
import ife from '../../../images/requisitos/credencial.png';
import comprobante from '../../../images/requisitos/comprobante_domicilio.png';
import Image from "react-bootstrap/cjs/Image";

class ContratacionesIndex extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 mt-5">
                        <h1 className="section-title text-center">¿Quieres ser parte de nuestro equipo?</h1>
                        <p className="text-center">Estos son los requisitos para ser un chofer de TaxisQualo</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-service-item">
                            <Image src={licencia} fluid className="service-icon"/>
                            <h4>Licencia de Chofer Vigente</h4>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-service-item">
                            <Image src={ife} fluid className="service-icon"/>
                            <h4>Credencial de Elector</h4>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-service-item">
                            <Image src={comprobante} fluid className="service-icon"/>
                            <h4>Comprobante de Domicilio</h4>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-service-item">
                            <Image src={antidoping} fluid className="service-icon"/>
                            <h4>Antidoping</h4>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-service-item">
                            <Image src={nopenales} fluid className="service-icon"/>
                            <h4>Carta de Antecedentes No Penales</h4>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-service-item">
                            <Image src={cv} fluid className="service-icon"/>
                            <h4>Solicitud de Trabajo</h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContratacionesIndex;