import React, { Component } from 'react';
import RentasService from './services/RentasService';
import KilometrajeService from './services/KilometrajeService';
import FormRenta from './FormRenta';
import FormKilometraje from './FormKilometraje';
import {toast} from 'react-toastify';

export default class Renta extends Component {

    constructor(props){
        super(props);
        this.url = process.env.PUBLIC_URL;
        this.kilometrajeService = new KilometrajeService();
        this.rentasService = new RentasService();
        this.state = {
            id_chofer: 0,
            title: 'Renta',
            fecha: 'Fecha',
            cuota: 0,
            deuda: 0,
            pago: 0,
            faltante: 0,
            kilometraje: {
                id: 0,
                km: 0
            },
            isUpdateKilometraje: false,
            estilo_renta: "col-md-4 offset-md-2",
            estilo_kilometraje: "col-md-4 ml-1"
        }
    }

    async componentDidMount(){  
        const query = new URLSearchParams(this.props.location.search);
        const idRenta = query.get('id_renta');
        const idTaxi = query.get('id_taxi');
        const idChofer = query.get('id_chofer');
        if(!idRenta && !idTaxi){
            this.props.history.push(this.url+'/rentas');
        }else{
            if(idRenta && idTaxi){
                this.setState({
                    estilo_renta: "col-md-4 offset-md-2",
                    estilo_kilometraje: "col-md-4 ml-1"
                });
            }else{
                if(idTaxi && !idChofer){
                    this.props.history.push(this.url+'/rentas');
                }
                if(idRenta){
                    this.setState({
                        estilo_renta: "col-md-8 offset-md-2 pl-1"
                    });
                }
                if(idTaxi){
                    this.setState({
                        estilo_kilometraje: "col-md-8 offset-md-2 pl-1"
                    });
                }
            }
        }
        this.props.match.params.id_renta = idRenta;
        this.props.match.params.id_taxi = idTaxi;
        if(this.props.match.params.id_renta){
            const res = await this.rentasService.getRentaById(this.props.match.params.id_renta);
            const renta = res.data;
            this.setState({
                id_chofer: renta.id_chofer,
                title: 'Renta para '+renta.chofer.nombre,
                fecha: 'Fecha: '+renta.fecha,
                cuota: renta.cuota_editable,
                deuda: renta.deuda_total,
                pago: renta.pago,
                faltante: renta.faltante
            });
        }
        
        const res = await this.kilometrajeService.getKilometrajeByTaxi(this.props.match.params.id_taxi);
        const kilometrajes = res.data;        

        if(kilometrajes.length) {
            const kilometraje = kilometrajes[0];
            this.setState({
                kilometraje: {
                    id: kilometraje.id,
                    km: kilometraje.kilometraje
                },
                isUpdateKilometraje: true
            })
        }else{
            this.setState({
                id_chofer: idChofer
            });
        }
    }

    onChange = e => {
        if(e.target.name === 'kilometraje') {
            this.setState({
                kilometraje: {
                    ...this.state.kilometraje,
                    km: e.target.value
                }
            });
        }else{
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }

    onSubmitRenta = async e => {
        e.preventDefault();
        try{
            const renta = {
                cuota: this.state.cuota,
                pago: this.state.pago
            }
            const salida = await this.rentasService.updateRenta(renta, this.props.match.params.id_renta);
            const data = salida.data;
            if(data.error){
                toast.error(data.error);
            }else
                this.props.history.push(this.url+'/rentas')
        }catch(error){
            console.log(error);   
        }
    }

    onSubmitKilometraje = async e => {
        e.preventDefault();
        try{
            let salida;
            if(this.state.isUpdateKilometraje){
                salida = await this.kilometrajeService.updateKilometraje(this.state.kilometraje);
            }else{
                salida = await this.kilometrajeService.createKilometraje(this.state.kilometraje, this.state.id_chofer);
            }
            const data = salida.data;
            if(data.error){
                toast.error(data.error);
            }else{
                this.props.history.push(this.url+'/rentas');
            }
        }catch(error){
            console.log(error);
        }
    }

    render(){
        let contenido;
        if(this.props.match.params.id_renta && this.props.match.params.id_taxi){
            contenido = 
            <div className="row">
                <div className="col-md-8 offset-md-2 text-center pl-1">
                    <div style={{border: "none"}} className="card card-body">
                        <h2>{this.state.fecha}</h2>
                    </div>
                </div>
                <FormRenta
                    title={this.state.title}
                    estilo={this.state.estilo_renta}
                    onSubmitRentaCallback={this.onSubmitRenta}
                    onChangeCallback={this.onChange}
                    cuota={this.state.cuota}
                    deuda={this.state.deuda}
                    pago={this.state.pago}
                    faltante={this.state.faltante}/>
                <FormKilometraje
                    estilo={this.state.estilo_kilometraje}
                    onSubmitKilometrajeCallback={this.onSubmitKilometraje}
                    km={this.state.kilometraje.km}
                    onChangeCallback={this.onChange}/>
            </div>
        }else{
            if(this.props.match.params.id_renta){
                contenido = 
                <div className="row">
                    <div className="col-md-8 offset-md-2 text-center pl-1">
                        <div style={{border: "none"}} className="card card-body">
                            <h2>{this.state.fecha}</h2>
                        </div>
                    </div>
                    <FormRenta
                        title={this.state.title}
                        estilo={this.state.estilo_renta}
                        onSubmitRentaCallback={this.onSubmitRenta}
                        onChangeCallback={this.onChange}
                        cuota={this.state.cuota}
                        deuda={this.state.deuda}
                        pago={this.state.pago}
                        faltante={this.state.faltante}/>
                </div>
            }else{
                contenido = 
                <div className="row">
                    <div className="col-md-8 offset-md-2 text-center pl-1">
                        <div style={{border: "none"}} className="card card-body">
                            <h2>{this.state.fecha}</h2>
                        </div>
                    </div>
                    <FormKilometraje
                        estilo={this.state.estilo_kilometraje}
                        onSubmitKilometrajeCallback={this.onSubmitKilometraje}
                        km={this.state.kilometraje.km}
                        onChangeCallback={this.onChange}/>
                </div>
            }
        }
        return (
            <div>
                {contenido}
            </div>
        );
    }

}