import axios from "axios/index";
import {API_BASE_URL, SESSION_KEY} from "../../../../config/api";
import SessionStorageService from "../../../auth/SessionStorageService";

export default class RentasService {

    constructor(){        
        this.api = axios.create({
            baseURL: API_BASE_URL,
            headers: this.createHeaders()
        });
    }

    createHeaders() {

        const headers = {'Content-Type': 'application/json'};

        const userData = SessionStorageService.get(SESSION_KEY);
        if (!!userData && typeof userData.api_token === 'string') {
            headers['Authorization'] = userData['api_token'];
        }

        return headers;
    }
    
    formatDate = () => {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    getAllRentas = () => {
        const fecha = this.formatDate();
        console.log(fecha);
        
        return this.api.get('/rentas?date='+fecha);
    }

    getRentaById = id => {
        return this.api.get('/renta/'+id);
    }

    updateRenta = (data, id) => {
        return this.api.put('/renta/'+id, data);
    }

    getTotalCaja = () => {
        const fecha = this.formatDate();
        return this.api.get('/rentas/caja/'+fecha);
    }

    getRentasByMonthYearChofer = (date, idChofer) => {
        let month = '' + (date.getMonth() + 1);
        const year = date.getFullYear();
    
        if (month.length < 2) {
            month = '0' + month;
        }
        
        return this.api.get('/rentas/'+idChofer+'?month='+month+'&year='+year);

    }

    createRenta = renta => this.api.post('/renta', renta);

}