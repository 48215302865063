import React from 'react';
import TaxisService from "../taxis/services/TaxisService";
//import Mapa from "../mapas/Mapa";

class TaxisIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {taxis: []};
    }

    render() {
        const {taxis} = this.state;
        return (
            <div className="container-fluid">
                <div className="row my-4">
                    <div className="col-12">
                        {//<Mapa taxis={taxis} />
                        }
                    </div>
                </div>
            </div>

        );
    }

    componentDidMount() {
        TaxisService.getAllTaxis().then(response => {
            this.setState({taxis: response.data});
        });
    }
}

export default TaxisIndex;