import React from 'react';
import ChoferService from "./services/ChoferService";
import {toast} from 'react-toastify';
import {DEBUG_MODE} from "../../../config/api";

class ChoferFormCreate extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmitNewChofer = this.handleSubmitNewChofer.bind(this);
        this.onChangeHandler = this.onSelectFotoChofer.bind(this);
        this.onChangeHandler = this.onSelectFotoLicencia.bind(this);
        this.onChangeHandler = this.onSelectFotoAntidoping.bind(this);
        this.onChangeHandler = this.onSelectFotoPenales.bind(this);

        this.state = {
            error: null,
            selectedFotoChofer: null,
            selectedFotoLicencia: null,
            selectedFotoAntidoping: null,
            selectedFotoPenales: null,
            mychofer: []
        };
    }

    handleSubmitNewChofer = (event) => {
        event.preventDefault();
        if (!event.target.checkValidity()) {
            if (DEBUG_MODE)
                console.warn('Invalid data!');
            toast.error('Por favor llena todos los campos');
            return;
        }

        const data = new FormData(event.target);
        console.log(data);
        
        if (this.state.selectedFotoChofer != null)
            data.append('foto_chofer', this.state.selectedFotoChofer, this.state.selectedFotoChofer.name);
        else
            data.append('foto_chofer', null);
        if (this.state.selectedFotoLicencia != null)
            data.append('foto_licencia', this.state.selectedFotoLicencia, this.state.selectedFotoLicencia.name);
        else
            data.append('foto_licencia', null);
        if (this.state.selectedFotoPenales != null)
            data.append('foto_nopenales', this.state.selectedFotoPenales, this.state.selectedFotoPenales.name);
        else
            data.append('foto_nopenales', null);
        if (this.state.selectedFotoAntidoping != null)
            data.append('foto_antidoping', this.state.selectedFotoAntidoping, this.state.selectedFotoAntidoping.name);
        else
            data.append('foto_antidoping', null);
        if (!data.get('id_chofer')) {
            ChoferService.createChofer(data).then((response) => {
                if (response.data.Error) {
                    toast.error(response.data.Error);
                    return;
                }
                const chofer = response.data;
                if (DEBUG_MODE)
                    console.log(chofer);
                const {nombre} = chofer;
                toast.success(`Chofer ${nombre} creado exitosamente`);
                this.props.onCreateChofer(chofer);

            }).catch((response) => {
                if (response.Error) {
                    toast.error(response.Error);
                } else
                    toast.error(response);
                if (DEBUG_MODE)
                    console.error(response);
            });
        } else {
            const id_chofer = data.get('id_chofer');
            ChoferService.updateChofer(data, id_chofer).then((response) => {
                if (response.data.Error) {
                    toast.error(response.data.Error);
                    return;
                }
                const chofer = response.data;
                if (DEBUG_MODE)
                    console.log(chofer);
                const {nombre} = chofer;
                toast.success(`Chofer ${nombre} creado exitosamente`);
                this.props.onUpdateChofer(chofer);

            }).catch((response) => {
                if (response.Error) {
                    toast.error(response.Error);
                } else
                    toast.error(response);
                if (DEBUG_MODE)
                    console.error(response);
            });
        }

    }

    onSelectFotoChofer = event => {
        this.setState({
            selectedFotoChofer: event.target.files[0],
        });
        if (DEBUG_MODE)
            console.log(`{"selectedFotoChofer": ${event.target.files[0]}`);
    };
    onSelectFotoLicencia = event => {
        this.setState({
            selectedFotoLicencia: event.target.files[0],
        });
        if (DEBUG_MODE)
            console.log(`{"selectedFotoLicencia": ${event.target.files[0]}`);
    };
    onSelectFotoAntidoping = event => {
        this.setState({
            selectedFotoAntidoping: event.target.files[0],
        });
        if (DEBUG_MODE)
            console.log(`{"selectedFotoAntidoping": ${event.target.files[0]}`);
    };
    onSelectFotoPenales = event => {
        this.setState({
            selectedFotoPenales: event.target.files[0]
        });
        if (DEBUG_MODE)
            console.log(`{"selectedFotoPenales": ${event.target.files[0]}`);
    };
    handleChange = (value) => {
        const data = this.state.mychofer;
        data[value['inputName']] = value['inputValue'];
        this.setState({
            mychofer: data
        });
    }

    componentDidUpdate(prevProps) {
        const {mychofer} = this.props;
        if (mychofer !== prevProps.mychofer) {
            mychofer.mypassword = mychofer.password;
            mychofer.password = "**********";
            this.setState({
                mychofer: mychofer
            });
        }
    }


    render() {
        const {mychofer} = this.state;
        const {taxis} = this.props;
        return (
            <div className="b-form">
                {mychofer.id && <h2>Editar Chofer</h2>}

                {!mychofer.id && <h2>Nuevo Chofer</h2>}
                <form onSubmit={this.handleSubmitNewChofer} noValidate>
                    <div className="form-group d-none">
                        <label htmlFor="id_chofer">Id</label>
                        <input type="hidden" className="form-control" id="id_chofer" name="id_chofer" required
                               value={mychofer.id && mychofer.id}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="nombre">Nombre</label>
                        <input type="text" className="form-control" id="nombre" name="nombre" required
                               value={mychofer.nombre ? mychofer.nombre: ""}
                               onChange={e => this.handleChange({
                                   "inputName": e.target.name,
                                   "inputValue": e.target.value
                               })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="usuario">Usuario</label>
                        <input type="text" className="form-control" id="usuario" name="usuario" required
                               value={mychofer.usuario ? mychofer.usuario: ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" id="password" name="password" required
                               value={mychofer.usuario ? mychofer.password: ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="direccion">Dirección</label>
                        <input type="text" className="form-control" id="direccion" name="direccion" required
                               value={mychofer.direccion ? mychofer.direccion: ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="telefono">Teléfono</label>
                        <input type="tel" className="form-control" id="telefono" name="telefono" required
                               value={mychofer.telefono ? mychofer.telefono: ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="celular">Celular</label>
                        <input type="tel" className="form-control" id="celular" name="celular" required
                               value={mychofer.id && mychofer.celular} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="ife">IFE /INE</label>
                        <input type="tel" className="form-control" id="ife" name="ife" required
                               value={mychofer.ife ? mychofer.ife: ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="fecha_nacimiento">Fecha de Nacimiento</label>
                        <input type="date" className="form-control" id="fecha_nacimiento" name="fecha_nacimiento"
                               value={mychofer.fecha_nacimiento ? mychofer.fecha_nacimiento: ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}
                               required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exp_licencia">Fecha de Expiración de Licencia</label>
                        <input type="date" className="form-control" id="exp_licencia" name="exp_licencia"
                               required value={mychofer.exp_licencia ? mychofer.exp_licencia: ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="taxi">Taxi Asignado</label>
                        <select className="form-control" id="taxi" name="taxi" required
                                value={mychofer.taxi ? mychofer.taxi: ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}>
                            <option value="">Seleccione</option>
                            {
                                taxis.map(taxi => (
                                    <option key={taxi.id}
                                            value={taxi.id}>#{taxi.id} - {taxi.marca} {taxi.modelo}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="comentarios">Comentarios</label>
                        <textarea className="form-control" id="comentarios" name="comentarios"
                                  value={mychofer.comentarios ? mychofer.comentarios: ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <select className="form-control" id="status" name="status" required
                                value={mychofer.status ? mychofer.status: ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}>
                            <option value="ACTIVO">ACTIVO</option>
                            <option value="INACTIVO">INACTIVO</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="foto_chofer">Foto del Chofer</label>
                        {mychofer.foto_chofer &&
                        <img src={mychofer.foto_chofer} alt="foto_chofer" className="img-fluid thumb"
                        />
                        }
                        <input type="file" className="form-control"
                               onChange={this.onSelectFotoChofer}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="foto_licencia">Foto del Licencia</label>
                        {mychofer.foto_licencia &&
                        <img src={mychofer.foto_licencia} alt="foto_licencia" className="img-fluid thumb"
                        />
                        }
                        <input type="file" className="form-control"
                               onChange={this.onSelectFotoLicencia}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="foto_antidoping">Foto del Antidoping</label>
                        {mychofer.foto_antidoping &&
                        <img src={mychofer.foto_antidoping} alt="foto_antidoping" className="img-fluid thumb"
                        />
                        }
                        <input type="file" className="form-control"
                               onChange={this.onSelectFotoAntidoping}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="foto_nopenales">Foto de Carta de Antecedentes no Penales</label>
                        {mychofer.foto_nopenales &&
                        <img src={mychofer.foto_nopenales} alt="foto_nopenales" className="img-fluid thumb"
                        />
                        }
                        <input type="file" className="form-control"
                               onChange={this.onSelectFotoPenales}/>
                    </div>

                    {mychofer.id &&
                    <button type="submit" className="btn btn-primary">Editar Chofer</button>
                    }
                    {!mychofer.id &&
                    <button type="submit" className="btn btn-primary">Registrar Chofer</button>
                    }
                </form>
            </div>
        );
    }
}

export default ChoferFormCreate;