const APPLICATION_CONSTANTS = {
    //BASE_URL: `http://taxisqualo.com/`,
    BASE_URL: `http://taxisqualo.com/`,
    APP_NAME: 'TaxisQualo',
    //Mensajes de Autenticación
    AUTH_LOGIN: 'Bienvenido!',
    AUTH_LOGOUT: 'Su sesión se ha cerrado.',
    AUTH_NO_PERMISSION: 'Usted no cuenta con los permisos necesarios para realizar esta acción',

    //Mensajes de Error General
    ERROR_GENERAL: 'Ha ocurrido un error, por favor contacte al administrador',
};

export default APPLICATION_CONSTANTS;