import React from 'react';
import ClientService from "./services/ClientService";
import ClientTable from "./ClientTable";
import ClientFormCreate from "./ClientFormCreate";

class ClientIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {clients: []};
    }

    render() {
        const {clients} = this.state;
        return (
            <div className="container-fluid">
                <div className="row my-4">
                    <div className="col-8">
                        <ClientTable clients={clients}/>
                    </div>
                    <div className="col-4">
                        <ClientFormCreate onCreateClient={this.handleCreateClient}/>
                    </div>
                </div>
            </div>

        );
    }

    handleCreateClient = (client) => {
        this.setState(prevState => ({
            clients: [...prevState.clients, client],
        }));
    };

    componentDidMount() {
        ClientService.getAllClients().then(response => {
            this.setState({clients: response.data});
        });
    }
}

export default ClientIndex;