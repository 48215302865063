import React from 'react';
import {toast} from 'react-toastify';
import AuthenticationService from "../../auth/AuthenticationService";
import {Redirect} from "react-router-dom";

import {DEBUG_MODE} from "../../../config/api";
import APPLICATION_CONSTANTS from "../../../config/messages";

class LoginForm extends React.Component {
    state = {
        redirectToReferrer: false,
    };
    handleLoginAction = (event) => {
        event.preventDefault();
        const isUser = this.props.isUser;
        if (!event.target.checkValidity()) {
            if (DEBUG_MODE)
                console.warn('Invalid data!');
            toast.error('Por favor llena todos los campos');
        }
        const data = new FormData(event.target);
        const email = data.get('correo');
        const usuario = data.get('usuario');
        const password = data.get('password');
        if (!isUser) {
            AuthenticationService.loginClientes(usuario, password).then((userData) => {
                if (userData.Error) {
                    toast.error(userData.Error);
                    return;
                }
                toast.success(APPLICATION_CONSTANTS.AUTH_LOGIN + " " + userData.nombre);
                this.props.onLogin();
                this.setState(() => ({
                    redirectToReferrer: true,
                }));
            }).catch((response) => {
                toast.error(response.error);
                if (DEBUG_MODE)
                    console.error(response);
            });
        } else {
            AuthenticationService.loginUsuarios(email, password).then((userData) => {
                if (userData.Error) {
                    toast.error(userData.Error);
                    return;
                }
                toast.success(APPLICATION_CONSTANTS.AUTH_LOGIN + " " + userData.nombre);
                this.props.onLogin();
                this.setState(() => ({
                    redirectToReferrer: true,
                }));
            }).catch((response) => {
                toast.error(response.error);
                if (DEBUG_MODE)
                    console.error(response);
            });
            
        }
    };

    render() {
        const {from} = this.props.location.state || {from: {pathname: '/'}};
        const {redirectToReferrer} = this.state;
        if (redirectToReferrer) {
            return <Redirect to={from}/>;
        }
        const isUser = this.props.isUser;
        return (

            <div className="col-12">
                <h1>Inicia Sesión {isUser && "Administador"}</h1>
                <form onSubmit={this.handleLoginAction} noValidate>
                    {isUser &&
                    <div className="form-group">
                        <label htmlFor="correo">Correo electrónico</label>
                        <input type="email" className="form-control" id="correo" name="correo"
                               aria-describedby="emailHelp" required="required"/>
                        <small id="emailHelp" className="form-text text-muted">We'll never share your email with
                            anyone else.
                        </small>
                    </div>
                    }
                    {!isUser &&
                    <div className="form-group">
                        <label htmlFor="usuario">Usuario</label>
                        <input type="text" className="form-control" id="usuario" name="usuario"
                               aria-describedby="emailHelp" required="required"/>
                        <small id="emailHelp" className="form-text text-muted">We'll never share your email with
                            anyone else.
                        </small>
                    </div>
                    }

                    <div className="form-group">
                        <label htmlFor="password">Contraseña</label>
                        <input type="password" className="form-control" required="required" id="password"
                               name="password"
                               autoComplete="off"/>
                    </div>

                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
        );
    }
}

export default LoginForm;