import React, { Component } from 'react';
import ChoferService from '../choferes/services/ChoferService';
import Select from 'react-select'
import moment from "moment";
import 'moment/locale/es';
import { toast } from 'react-toastify';
import RentasService from './services/RentasService';

export default class CreaRenta extends Component {

    constructor(props){
        super(props);
        this.state = {
            options: [],
            value: {},
            date: moment().format('YYYY-MM-DD'),
            pago: 0,
            cuota: 0
        }
        this.rentaService = new RentasService();
    }

    onChangeSelect = e => this.setState({
        value: e
    });
    

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onSubmit = async e => {
        e.preventDefault();
        if(!this.state.value.value) {
            toast.error('Debe seleccionar un chofer');
            return;
        }
        const renta = {
            id_chofer: this.state.value.value,
            id_taxi: this.state.value.id_taxi,
            fecha: this.state.date,
            cuota: this.state.cuota,
            pago: this.state.pago
        }
        try{
            const response = await this.rentaService.createRenta(renta);
            const data = response.data;
            if(data.error){
                toast.error(data.error);
            }else{
                toast.success('Renta creada con éxito');
                this.setState({
                    options: [],
                    value: {},
                    date: moment().format('YYYY-MM-DD'),
                    pago: 0,
                    cuota: 0
                });
            }
        }catch(err){
            console.log(err);            
        }
        
    }

    render(){
        return (
            <div className="row">
                <div className="col-md-4 offset-md-4 text-center pl-1">
                    <div style={{border: "none"}} className="card card-body">
                        <h2>Crear Renta</h2>
                    </div>
                    <div className="card card-body">
                        <form onSubmit={this.onSubmit} className="form-group">
                        <label>Seleccione chofer</label>
                            <Select
                                options={this.state.options}
                                placeholder="Seleccione chofer"
                                onChange={this.onChangeSelect}
                                value={this.state.value}
                            /><br/>
                            <label>Seleccione la fecha</label>
                            <input
                                className="form-control"
                                value={this.state.date}
                                onChange={this.onChange}
                                name="date"
                                type="date"
                            /><br/>
                            <label>Ingrese la cuota</label>
                            <input
                                className="form-control"
                                type="number"
                                name="cuota"
                                onChange={this.onChange}
                                value={this.state.cuota}
                            /><br/>
                            <label>Ingrese el pago</label>
                            <input
                                className="form-control"
                                type="number"
                                name="pago"
                                onChange={this.onChange}
                                value={this.state.pago}
                            /><br/>
                            <input
                                type="submit"
                                value="Crear"
                                className="m-2 btn btn-primary btn-block "
                            />
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    async componentDidMount(){
        try{
            const response = await ChoferService.getAllChoferes();
            const choferes = response.data;
            const options = choferes.map(chofer => {
                return {
                    label: chofer.nombre,
                    value: chofer.id,
                    id_taxi: chofer.id_taxi
                }
            });
            this.setState({
                options: options
            });
            console.log(response.data);
        }catch(err){
            console.log(err);            
        }
    }

}