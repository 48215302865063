import React from 'react';
import LoginForm from "./LoginForm";

class LoginComponent extends React.Component {

    render() {
        const {location, onLogin, isUser} = this.props;
        return (
            <div className="row m-5">
                <div className="col-md-6 col-xs-12 mx-auto">
                    <LoginForm location={location} onLogin={onLogin} isUser={isUser}/>
                </div>
            </div>
        );
    }
}

export default LoginComponent;