import {API_BASE_URL, DEBUG_MODE, SESSION_KEY} from "../../config/api";

import axios from "axios/index";
import SessionStorageService from "./SessionStorageService";

class AuthenticationService {
    static hasRole(role) {
        const userData = SessionStorageService.get(SESSION_KEY);
        if (DEBUG_MODE)
            console.log(userData);
        return (userData.rol === role);
    }

    static getRole() {
        const userData = SessionStorageService.get(SESSION_KEY);
        if (DEBUG_MODE)
            console.log(userData);
        if (userData)
            return userData.rol;
        return null;
    }

    static hasPermission(role) {
        const userData = SessionStorageService.get(SESSION_KEY);
        if (DEBUG_MODE)
            console.log(userData);
        return userData.permissions.includes(role);

    }

    static loginClientes(email, password) {
        return new Promise((resolve, reject) => {
            const RequestHeaders = {
                'Content-Type': 'application/json',
            };
            const RequestConfig = {headers: RequestHeaders};
            const RequestUrl = `${API_BASE_URL}/choferes/login`;
            const RequestData = {
                correo: email,
                password: password
            };
            return axios.post(RequestUrl, RequestData, RequestConfig).then((response) => {
                const userData = response.data;
                if (DEBUG_MODE)
                    console.log('Client from Lumen', userData);
                this.isAuthenticated = true;
                this.userData = userData;
                //TODO: Save this user in to session storage
                SessionStorageService.set(SESSION_KEY, userData);
                resolve(userData);
            }).catch(error => reject(error));
        });
    }

    static loginUsuarios(email, password) {
        return new Promise(async (resolve, reject) => {
            const RequestHeaders = {
                'Content-Type': 'application/json',
            };
            const RequestConfig = {headers: RequestHeaders};
            const RequestUrl = `${API_BASE_URL}/usuarios/login`;
            const RequestData = {
                correo: email,
                password: password
            };
            try {
                const response = await axios.post(RequestUrl, RequestData, RequestConfig);
                const userData = response.data;
                if (DEBUG_MODE)
                    console.log('User from Lumen', userData);
                this.isAuthenticated = true;
                this.userData = userData;
                //TODO: Save this user in to session storage
                SessionStorageService.set(SESSION_KEY, userData);
                resolve(userData);
            }
            catch (error) {
                return reject(error);
            }
        });
    }

    static isUserAuthenticated() {
        const userData = SessionStorageService.get(SESSION_KEY);
        this.isAuthenticated = (!!userData && typeof userData.api_token === 'string');
        return this.isAuthenticated;
    }

    static logout() {
        return new Promise((resolve, _) => {
            this.isAuthenticated = false;
            SessionStorageService.remove(SESSION_KEY);
            //TODO: Add backend calls in case you need more stuff!!
            //por ejemplo llamar a la funcion logout del backend para destruir el api-token

            resolve();
        });
    }
}

export default AuthenticationService;