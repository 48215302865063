import React, { Component } from 'react';
import FormAceite from './FormAceite';
import FormAfinacion from '../afinaciones/FormAfinacion';

export default class Aceites extends Component {

    constructor(props){
        super(props);
        this.idTaxi = props.match.params.id_taxi;
    }

    render(){
        return (
            <div className="m-2 row">
                <div className="col-md-4 offset-md-4 text-center pl-1">
                    <FormAceite
                        id_taxi={this.idTaxi}/>
                    <FormAfinacion
                        className="mt-2"
                        id_taxi={this.idTaxi}/>
                </div>
            </div>
        );
    }

}