import React from 'react';
import ConfiguracionService from "./services/ConfiguracionService";
import {toast} from 'react-toastify';
import {DEBUG_MODE} from "../../../config/api";

class ConfiguracionFormCreate extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            configuracion: [],
            myconfiguracion: [],
            error: null,
        };
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (!event.target.checkValidity()) {
            if (DEBUG_MODE)
                console.warn('Invalid data!');
            toast.error('Por favor llena todos los campos');
            return;
        }
        const data = new FormData(event.target);
        const id = data.get('id');
        ConfiguracionService.updateConfiguracion(data, id).then((response) => {
            if (response.data.Error) {
                toast.error(response.data.Error);
                return;
            }
            const configuracion = response.data;
            if (DEBUG_MODE)
                console.log(configuracion);

            toast.success(`Configuración actualizada`);
            this.props.onUpdateConfiguracion(configuracion);

        }).catch((response) => {
            const {error} = response;
            toast.error(`Error ${error}`);
            if (DEBUG_MODE)
                console.error(response);
        });
    };
    handleChange = (value) => {
        const data = this.state.configuracion;
        data[value['inputName']] = value['inputValue'];
        this.setState({
            configuracion: data
        });
    }

    componentDidUpdate(prevProps) {
        const {myconfiguracion} = this.props;
        if (myconfiguracion !== prevProps.myconfiguracion) {
            this.setState({
                configuracion: myconfiguracion
            });
        }
    }

    render() {

        const {configuracion} = this.state;

        return (
            <div className="b-form">
                <h2>Editar Configuración</h2>
                <form onSubmit={this.handleSubmit} noValidate>
                    <div className="form-group invisible">
                        <input type="hidden" className="form-control" id="id" name="id" required
                               value={configuracion.id ? configuracion.id : ""}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="precio_bandera">Precio por Metro</label>
                        <input type="text" className="form-control" id="precio_metro" name="precio_metro" required
                               value={configuracion.precio_metro ? configuracion.precio_metro : ""}
                               onChange={e => this.handleChange({
                                   "inputName": e.target.name,
                                   "inputValue": e.target.value,
                               })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="precio_bandera">Precio Bandera</label>
                        <input type="text" className="form-control" id="precio_bandera" name="precio_bandera" required
                               value={configuracion.precio_bandera ? configuracion.precio_bandera : ""}
                               onChange={e => this.handleChange({
                                   "inputName": e.target.name,
                                   "inputValue": e.target.value,

                               })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="porcentaje">Porcentaje</label>
                        <input className="form-control" id="porcentaje" name="porcentaje" required
                               value={configuracion.porcentaje ? configuracion.porcentaje : ""}
                               onChange={e => this.handleChange({
                                   "inputName": e.target.name,
                                   "inputValue": e.target.value,

                               })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="porcentaje">Horas</label>
                        <input className="form-control" id="horas" name="horas" required
                               value={configuracion.horas ? configuracion.horas : ""}
                               onChange={e => this.handleChange({
                                   "inputName": e.target.name,
                                   "inputValue": e.target.value,

                               })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="porcentaje">Kilometros</label>
                        <input className="form-control" id="km" name="km" required
                               value={configuracion.km ? configuracion.km : ""}
                               onChange={e => this.handleChange({
                                   "inputName": e.target.name,
                                   "inputValue": e.target.value,

                               })}/>
                    </div>

                    <button type="submit" className="btn btn-primary">Actualizar</button>
                </form>
            </div>
        );
    }
}

export default ConfiguracionFormCreate;