import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCalendar, faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default class BigCalendarToolbar extends Toolbar {

	componentDidMount() {
		const view = this.props.view;
		console.log(view)
	}

	render() {
		return (
			<div className="m-3">
                <div className="rbc-toolbar-label text-center">
                    <h3>{this.props.label}</h3>
                </div>
				<div className="rbc-btn-group">
                    <button
                        className="btn btn-info"
                        onClick={() => this.navigate('PREV')}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                    </button>
                    <button
                        className="btn btn-info"
                        onClick={() => this.navigate('TODAY')}>
                        <FontAwesomeIcon icon={faCalendar}/>
                    </button>
                    <button
                        className="btn btn-info"
                        onClick={() => this.navigate('NEXT')}>
                        <FontAwesomeIcon icon={faArrowRight}/>
                    </button>
				</div>
			</div>
		);
	}
}