import React from 'react';
import UsersService from "./services/UsersService";
import UsersTable from "./UsersTable";
import UsersFormCreate from "./UsersFormCreate";

class UsersIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {users: [], myuser: []};
    }

    render() {
        const {users, myuser} = this.state;
        return (
            <div className="container-fluid">
                <div className="row my-4">
                    <div className="col-8">
                        <UsersTable users={users} onGetUser={this.handleOnGetUser} onUpdateUserStatus={this.handleUpdateUserStatus}
                                    onDeleteUser={this.handleOnDeleteUser}/>
                    </div>
                    <div className="col-4">
                        <UsersFormCreate onCreateUser={this.handleOnCreateUser} onUpdateUser={this.handleUpdateUser} myuser={myuser}/>
                    </div>
                </div>
            </div>

        );
    }
    handleOnCreateUser = (user) => {
        this.setState(prevState => ({
            users: [...prevState.users, user],
        }));
    };

    handleUpdateUserStatus = (updatedUser) => {
        this.setState(
            prevState => {
                // Find the user
                const indexToUpdate = prevState.users.findIndex(user => user.id === updatedUser.id);
                // Modify the user
                prevState.users[indexToUpdate].status = updatedUser.status;
                // Return a new array with modified user
                return {
                    users: [...prevState.users],
                };
            });
    }
    handleUpdateUser = (updatedUser) => {
        this.setState(
            prevState => {
                // Find the user
                const indexToUpdate = prevState.users.findIndex(user => user.id === updatedUser.id);
                // Modify the user
                prevState.users[indexToUpdate] = updatedUser;
                // Return a new array with modified user
                return {
                    users: [...prevState.users],
                };
            });
        this.setState({
            myuser: []
        });
    }
    handleOnDeleteUser = (deletedUser) => {
        this.setState(
            prevState => ({
                users: prevState.users.filter(
                    user => user.id !== deletedUser.id),
            }));
    };
    handleOnGetUser = (user) => {
        this.setState({
            myuser: user
        });
        this.render();
    };

    componentDidMount() {
        UsersService.getAllUsers().then(response => {
            this.setState({users: response.data.usuarios});
        });
    }
}

export default UsersIndex;