import React from 'react';
import ConfiguracionService from "./services/ConfiguracionService";
import {DEBUG_MODE} from "../../../config/api";
import {toast} from "react-toastify";
import {faEdit} from "@fortawesome/free-solid-svg-icons/index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";


class ConfiguracionTable extends React.Component {
    handleEditConfiguracion = (event) => {
        const data = event;
        if (data !== null) {
            ConfiguracionService.getConfiguracion(data).then((response) => {
                const configuracion = response.data;
                if (DEBUG_MODE)
                    console.log(configuracion);
                this.props.onGetConfiguracion(configuracion);

            }).catch((response) => {
                toast.error(`No se ha podido crear el chofer`);
                if (DEBUG_MODE)
                    console.error(response);
            });
        } else {
            const configuracion = [];
            this.props.onGetConfiguracion(configuracion);
        }
    };

    render() {
        const {configuracion} = this.props;
        return (
            <div>
                <h1>Lista de Configuración</h1>
                <div className="table-responsive">
                    <table className="table">
                        <thead className="thead-dark">
                        <tr>
                            <th scope="col">Precio por Metro</th>
                            <th scope="col">Precio Bandera</th>
                            <th scope="col">Porcentaje</th>
                            <th scope="col">Horas </th>
                            <th scope="col">Km</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            configuracion.map(config => (
                                <tr key={config.id.toString()}>
                                    <td>{config.precio_metro.toString()}</td>
                                    <td>{config.precio_bandera.toString()}</td>
                                    <td>{config.porcentaje.toString()}</td>
                                    <td>{config.horas.toString()}</td>
                                    <td>{config.km.toString()}</td>
                                    <td>
                                        <button className="btn btn-primary"
                                                onClick={() => this.handleEditConfiguracion(config.id)}><FontAwesomeIcon
                                            icon={faEdit}/></button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default ConfiguracionTable;