import axios from "axios/index";
import {API_BASE_URL, SESSION_KEY} from "../../../../config/api";
import SessionStorageService from "../../../auth/SessionStorageService";

export default class KilometrajeService {

    constructor(){        
        this.api = axios.create({
            baseURL: API_BASE_URL,
            headers: this.createHeaders()
        });
    }

    createHeaders() {

        const headers = {'Content-Type': 'application/json'};

        const userData = SessionStorageService.get(SESSION_KEY);
        if (!!userData && typeof userData.api_token === 'string') {
            headers['Authorization'] = userData['api_token'];
        }

        return headers;
    }

    getKilometrajeByTaxi = (id, date = null) => {
        if(date !== null){
            return this.api.get('/kilometrajes?id='+id+"&by_taxi=1&fecha="+date);
        }else
            return this.api.get('/kilometrajes?id='+id+"&by_taxi=1");
    }

    createKilometraje = (kilometraje, id_chofer, date = null) => {
        const data = {
            kilometraje: kilometraje.km,
            id_chofer: id_chofer
        }
        console.log(date);
        
        if(date !== null){
            console.log('no');
            
            let month = '' + (date.getMonth() + 1);
            const year = date.getFullYear();
            let day = '' + date.getDate();
        
            if (month.length < 2) {
                month = '0' + month;
            }
            if (day.length < 2) {
                day = '0' + day;
            }
            data.fecha = year+'-'+month+'-'+day;
            console.log(data.fecha);
            
        }
        return this.api.post('/kilometraje', data);
    }

    updateKilometraje = kilometraje => {
        console.log(kilometraje);
        
        const data = {
            kilometraje: kilometraje.km
        }
        return this.api.put('/kilometraje/'+kilometraje.id, data);
    }

    getKilometrajesByMonthYearChofer = (date, idChofer) => {
        let month = '' + (date.getMonth() + 1);
        const year = date.getFullYear();
    
        if (month.length < 2) {
            month = '0' + month;
        }
        return this.api.get('/kilometrajes/'+idChofer+'?month='+month+'&year='+year);
    }

}