import React from 'react';
import CarreraService from "./services/CarreraService";
import {toast} from 'react-toastify';
import {DEBUG_MODE} from "../../../config/api";

class CarreraFormCreate extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            choferes: [],
            mycarrera: [],
            error: null,
        };
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (!event.target.checkValidity()) {
            if (DEBUG_MODE)
                console.warn('Invalid data!');
            toast.error('Por favor llena todos los campos');
            return;
        }
        const data = new FormData(event.target);
        CarreraService.createCarrera(data).then((response) => {
            if (response.data.Error) {
                toast.error(response.data.Error);
                return;
            }
            const carrera = response.data;
            if (DEBUG_MODE)
                console.log(carrera);
            const {marca} = carrera;
            toast.success(`Carrera ${marca} creado exitosamente`);
            this.props.onCreateCarrera(carrera);

        }).catch((response) => {
            const {error} = response;
            toast.error(`Error ${error}`);
            if (DEBUG_MODE)
                console.error(response);
        });
    };
    handleChange = (value) => {
        const data = this.state.mycarrera;
        data[value['inputName']] = value['inputValue'];
        var index = value['extra'].selectedIndex;
        var optionElement = value['extra'].childNodes[index];
        if (value['inputName'] === "chofer") {
            data['taxi'] =   optionElement.getAttribute('chofer');
        }
        if (value['inputName'] === "taxi") {
            data['chofer'] =  optionElement.getAttribute('taxi');
        }
        this.setState({
            mycarrera: data
        });
    }

    componentDidUpdate(prevProps) {
        const {mycarrera} = this.props;
        if (mycarrera !== prevProps.mycarrera) {
            this.setState({
                mycarrera: mycarrera
            });
        }
    }

    render() {
        const {choferes, taxis} = this.props;
        const {mycarrera} = this.state;
        return (
            <div className="b-form">
                <h2>Nueva Carrera</h2>
                <form onSubmit={this.handleSubmit} noValidate>
                    <div className="form-group">
                        <label htmlFor="origen">Origen</label>
                        <input type="text" className="form-control" id="origen" name="origen"
                               value={mycarrera.origen ? mycarrera.origen : ""} required
                               onChange={e => this.handleChange({
                                   "inputName": e.target.name,
                                   "inputValue": e.target.value,
                                   "extra": e.target
                               })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="destino">Destino</label>
                        <input type="text" className="form-control" id="destino" name="destino" required
                               value={mycarrera.destino ? mycarrera.destino : ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value,
                            "extra": e.target
                        })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="cliente">Cliente</label>
                        <input type="text" className="form-control" id="cliente.nombre" name="cliente.nombre" required
                               value={mycarrera.cliente ? mycarrera.cliente.nombre : ""}
                               onChange={e => this.handleChange({
                                   "inputName": e.target.name,
                                   "inputValue": e.target.value,
                                   "extra": e.target
                               })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="fecha">Fecha en que requiere la carrera</label>
                        <input type="datetime" className="form-control" id="fecha" name="fecha" required
                               value={mycarrera.fecha_solicitud ? mycarrera.fecha_solicitud : ""}
                               onChange={e => this.handleChange({
                                   "inputName": e.target.name,
                                   "inputValue": e.target.value,
                                   "extra": e.target
                               })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="taxi">Taxi</label>
                        <select className="form-control" id="taxi" name="taxi" required
                                value={mycarrera.taxi ? mycarrera.taxi.id : ""}
                                onChange={e => this.handleChange({
                                    "inputName": e.target.name,
                                    "inputValue": e.target.value,
                                    "extra": e.target
                                })}>
                            <option>Seleccione</option>
                            {
                                taxis.map(taxi => (
                                    <option value={taxi.id.toString()} key={taxi.id.toString()}
                                            chofer={taxi.chofer}>{"#" + taxi.id.toString() + " - " + taxi.marca + " " + taxi.modelo}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="form- group">
                        <label htmlFor="chofer">Chofer</label>
                        <select className="form-control" id="chofer" name="chofer" required
                                value={mycarrera.chofer ? mycarrera.chofer.nombre : ""}
                                onChange={e => this.handleChange({
                                    "inputName": e.target.name,
                                    "inputValue": e.target.value,
                                    "extra": e.target
                                })}>
                            <option>Seleccione</option>
                            {
                                choferes.map(chofer => (
                                    <option value={chofer.id.toString()}  key={chofer.id.toString()} taxi={chofer.taxi}>{chofer.nombre}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <select className="form-control" id="status" name="status" required
                                value={mycarrera.status ? mycarrera.status : ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value,
                            "extra": e.target
                        })}>
                            <option value="SOLICITADA">SOLICITADA</option>
                            <option value="TAXISTA_ASIGNADO">TAXISTA_ASIGNADO</option>
                            <option value="CARRERA_CANCELADA">CARRERA_CANCELADA</option>
                        </select>
                    </div>
                    <button type="submit" className="btn btn-primary">Registrar Carrera</button>
                </form>
            </div>
        );
    }
}

export default CarreraFormCreate;