import React from 'react';
import {DEBUG_MODE} from "../../../config/api";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import UsersService from "./services/UsersService";

class UsersTable extends React.Component {

    handleChangeStatus = (event) => {
        const data = event;
        console.log(data);
        const {id} = data;
        const status = data.status === 'ACTIVO' ? 'INACTIVO' : 'ACTIVO';
        UsersService.updateUserStatus(id, status).then((response) => {
            const user = response.data;
            if (DEBUG_MODE)
                console.log(user);
            const {status, nombre} = user;
            toast.success(`Usuario ${nombre} ha quedado ${status} `);
            this.props.onUpdateUserStatus(user);

        }).catch((response) => {
            toast.error(`No se ha podido actualiza el estado del user`);
            if (DEBUG_MODE)
                console.error(response);
        });
    };
    handleDeleteUser = (event) => {
        const data = event;
        UsersService.deleteUser(data).then((response) => {
            const user = response.data;
            if (DEBUG_MODE)
                console.log(user);
            const {nombre} = user;
            toast.warn(`Usuario ${nombre} ha sido eliminado.`);
            this.props.onDeleteUser(user);
        }).catch((response) => {
            toast.error(`No se ha podido obtener los datos del usuario`);
            if (DEBUG_MODE)
                console.error(response);
        });
    }
    handleEditUser = (event) => {
        const data = event;
        if (data !== null) {
            UsersService.getUser(data).then((response) => {
                const user = response.data;
                this.setState({user});
                if (DEBUG_MODE)
                    console.log(user);
                this.props.onGetUser(user);
            }).catch((response) => {
                toast.error(`No se ha podido obtener los datos del user`);
                if (DEBUG_MODE)
                    console.error(response);
            });
        } else {
            const user = [];
            this.props.onGetUser(user);
        }
    };

    render() {
        const {users} = this.props;
        return (
            <div>
                <h1>Lista de Usuarios <button className="btn btn-primary float-right"
                                             onClick={() => this.handleEditUser(null)}>Nuevo Usuario</button></h1>
                <div className="table-responsive">
                    <table className="table">
                        <thead className="thead-dark">
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Correo</th>
                            <th scope="col">Permiso</th>
                            <th scope="col">Status</th>
                            <th scope="col">Último acceso</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            users.map(user => (
                                <tr key={user.id.toString()}>
                                    <td>{user.id.toString()}</td>
                                    <td>{user.nombre}</td>
                                    <td>{user.correo}</td>
                                    <td>{user.rol}</td>
                                    <td>
                                        <button className="btn btn-info"
                                                onClick={() => this.handleChangeStatus(user)}>{user.status}</button>
                                    </td>
                                    <td>{user.ultima_sesion}</td>
                                    <td>
                                        <button className="btn btn-primary"
                                                onClick={() => this.handleEditUser(user.id)}>
                                            <FontAwesomeIcon icon={faEdit}/>
                                        </button>
                                        <button className="btn btn-danger"
                                                onClick={() => this.handleDeleteUser(user.id)}>
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default UsersTable;