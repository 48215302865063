import React from 'react';
import { Component } from 'react';
import RentasService from './services/RentasService';

export default class Caja extends Component {

    constructor(props){
        super(props);
        this.rentaService = new RentasService();
        this.state = {
            fecha: '',
            total_rentas: 0,
            total_pago: 0,
            total_deuda: 0
        }
    }

    async componentDidMount(){
        const fecha = this.rentaService.formatDate();
        this.setState({
            fecha: fecha
        });
        try{
            const response = await this.rentaService.getTotalCaja();
            const data = response.data;
            this.setState({
                total_rentas: data.total_rentas,
                total_pago: data.total_pago,
                total_deuda: data.total_deuda
            });        
        }catch(error){
            console.log(error);
        }
    }

    render(){
        return (
            <div className="card card-body">
                <h3 className="text-center">
                    <p>Caja</p>
                </h3>
                <h4 className="text-center">
                    <p>
                        {this.state.fecha}
                    </p>
                </h4>
                <div className="form-group">
                    <div className="m-2 text-center">
                        <p>
                            <label>Rentas diarias:</label>
                            <strong className="ml-1">{"$"+this.state.total_rentas}</strong>
                        </p>
                        <p>
                            <label>Abonos de rentas:</label>
                            <strong className="ml-1">{"$"+this.state.total_pago}</strong>
                        </p>
                        <p>
                            <label>Deuda total completa:</label>
                            <strong className="ml-1">{"$"+this.state.total_deuda}</strong>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

}