import React, { Component } from "react";
import RentasTable from "./RentasTable";
import RentasService from './services/RentasService'
import Caja from "./Caja";
import { Link } from "react-router-dom";
import TableAceites from "../aceites/TableAceites";

export default class Rentas extends Component{

    constructor(props) {
        super(props);
        this.state = {
            rentas: [],
            rentasFiltradas: []
        }
        this.rentasService = new RentasService();
    }

    onChangeFilter = e => {
        if(e.target.value === '') this.setState({rentasFiltradas: this.state.rentas});
        const filtro = this.state.rentas.filter(renta => renta.chofer.nombre.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
        if(filtro.length === 0) this.setState({rentasFiltradas: this.state.rentas});
        this.setState({
            rentasFiltradas: filtro
        });
        
    }

    render(){
        return (
            <div className="container-fluid">
                <div className="row my-4">
                    <div className="col-md-8">
                        <div className="m-2">
                            <input
                                type="text"
                                placeholder="Buscar..."
                                onChange={this.onChangeFilter}/>
                            <Link className="m-2 btn btn-primary float-right" to="/crearenta">Crear renta</Link>
                        </div>
                        <RentasTable
                            rentas={this.state.rentasFiltradas}/>
                    </div>
                    <div className="col-md-4">
                        <Caja/>
                        <TableAceites/>
                    </div>
                </div>
            </div>
        );
    }

    async componentDidMount(){
        try{
            const response = await this.rentasService.getAllRentas();            
            this.setState({
                rentas: response.data,
                rentasFiltradas: response.data
            });
        }catch(error){
            console.log(error);
        }
    }

};