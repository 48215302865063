import React from 'react';
import TaxisService from "./services/TaxisService";
import TaxisTable from "./TaxisTable";
import TaxisFormCreate from "./TaxisFormCreate";

class TaxisIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {taxis: [], mytaxi: []};
    }

    render() {
        const {taxis, mytaxi} = this.state;
        return (
            <div className="container-fluid">
                <div className="row my-4">
                    <div className="col-8">
                        <TaxisTable taxis={taxis} onGetTaxi={this.handleOnGetTaxi}
                                    onUpdateTaxiStatus={this.handleUpdateTaxiStatus}
                                    onDeleteTaxi={this.handleOnDeleteTaxi}/>
                    </div>
                    <div className="col-4">
                        <TaxisFormCreate onCreateTaxi={this.handleOnCreateTaxi} onUpdateTaxi={this.handleUpdateTaxi}
                                         mytaxi={mytaxi}/>
                    </div>
                </div>
            </div>

        );
    }

    handleOnCreateTaxi = (taxi) => {
        this.setState(prevState => ({
            taxis: [...prevState.taxis, taxi],
        }));
    };
    handleUpdateTaxiStatus = (updatedTaxi) => {
        this.setState(
            prevState => {
                // Find the user
                const indexToUpdate = prevState.taxis.findIndex(taxi => taxi.id === updatedTaxi.id);
                // Modify the user
                prevState.taxis[indexToUpdate].status = updatedTaxi.status;
                // Return a new array with modified user
                return {
                    taxis: [...prevState.taxis],
                };
            });
    }
    handleUpdateTaxi = (updatedTaxi) => {
        this.setState(
            prevState => {
                // Find the user
                const indexToUpdate = prevState.taxis.findIndex(taxi => taxi.id === updatedTaxi.id);
                // Modify the user
                prevState.taxis[indexToUpdate] = updatedTaxi;
                // Return a new array with modified user
                return {
                    taxis: [...prevState.taxis],
                };
            });
        this.setState({
            mytaxi: []
        });
    }
    handleOnDeleteTaxi = (deletedTaxi) => {
        this.setState(
            prevState => ({
                taxis: prevState.taxis.filter(
                    taxi => taxi.id !== deletedTaxi.id),
            }));
    };
    handleOnGetTaxi = (taxi) => {
        this.setState({
            mytaxi: taxi
        });
        this.render();
    };

    componentDidMount() {
        TaxisService.getAllTaxis().then(response => {
            this.setState({taxis: response.data});
        });
    }
}

export default TaxisIndex;