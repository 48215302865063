import React from 'react';

class PublicHomeIndex extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="row my-4">
                    <div className="col-md-12">
                        <h1>Inventario</h1>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btn btn-primary">Alta</button>
                            </div>

                            <div className="col-md-6">
                                <button className="btn btn-danger">Baja</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PublicHomeIndex;