import React from 'react';
import CarreraService from "./services/CarreraService";
import CarreraTable from "./CarreraTable";
import CarreraFormCreate from "./CarreraFormCreate";
import ChoferesService from "../choferes/services/ChoferService";
import TaxisService from "../taxis/services/TaxisService";
import CarreraFilter from "./CarreraFilter";

class CarreraIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {carreras: [], taxis: [], choferes: [], mycarrera: [], filter: []};
    }

    render() {
        const {carreras} = this.state;
        const {choferes, taxis, mycarrera, filter} = this.state;
        return (
            <div className="container-fluid">
                <div className="row my-4">
                    <div className="col-10">
                        <h1>Lista de Carreras</h1>
                        <CarreraFilter filter={filter} taxis={taxis} choferes={choferes} onUpdateFilter={this.handleUpdateFilters}/>
                        <CarreraTable carreras={carreras} choferes={choferes} onGetCarrera={this.handleGetCarrera}/>
                    </div>
                    <div className="col-2">
                        <CarreraFormCreate onCreateTaxi={this.handleCreateTaxi} taxis={taxis} choferes={choferes}
                                           mycarrera={mycarrera}/>
                    </div>
                </div>
            </div>

        );
    }

    handleGetCarrera = (carrera) => {
        this.setState({
            mycarrera: carrera
        });
        this.render();
    };


    handleCreateTaxi = (user) => {
        this.setState(prevState => ({
            carreras: [...prevState.carreras, user],
        }));
    };
    handleUpdateFilters = (filter) => {
        this.setState({
            filter: filter
        });
        CarreraService.getFilterCarreras(filter).then(response => {
            if (response.data.Error)
                this.setState({carreras: []});
            else
                this.setState({carreras: response.data});
        });
        this.render();

    }

    componentDidMount() {
        const filter = this.state.filter;
        CarreraService.getAllCarreras(filter).then(response => {
            this.setState({carreras: response.data});
        });
        ChoferesService.getAllChoferes().then(response => {
            this.setState({choferes: response.data});
        });
        TaxisService.getAllTaxis().then(response => {
            this.setState({taxis: response.data});
        });
    }
}

export default CarreraIndex;