import React from 'react';
import ChoferService from "./services/ChoferService";
import ChoferTable from "./ChoferTable";
import ChoferFormCreate from "./ChoferFormCreate";
import TaxisService from "../taxis/services/TaxisService";

class ChoferIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {choferes: [], taxis: [], mychofer: []};
    }

    render() {
        const {choferes, taxis, mychofer} = this.state;

        return (
            <div className="container-fluid">
                <div className="row my-4">
                    <div className="col-8">
                        <ChoferTable choferes={choferes} onGetChofer={this.handleOnGetChofer}
                                     onUpdateChoferStatus={this.handleUpdateChoferStatus}
                                     onDeleteChofer={this.handleOnDeleteChofer}/>
                    </div>
                    <div className="col-4">
                        <ChoferFormCreate taxis={taxis} onCreateChofer={this.handleCreateChofer} onUpdateChofer={this.handleUpdateChofer} mychofer={mychofer}/>
                    </div>
                </div>
            </div>

        );
    }

    handleUpdateChoferStatus = (updatedChofer) => {
        this.setState(
            prevState => {
                // Find the user
                const indexToUpdate = prevState.choferes.findIndex(chofer => chofer.id === updatedChofer.id);
                // Modify the user
                prevState.choferes[indexToUpdate] = updatedChofer;
                // Return a new array with modified user
                return {
                    choferes: [...prevState.choferes],
                };
            });
    }
    handleUpdateChofer = (updatedChofer) => {
        this.setState(
            prevState => {
                // Find the user
                const indexToUpdate = prevState.choferes.findIndex(chofer => chofer.id === updatedChofer.id);
                // Modify the user
                prevState.choferes[indexToUpdate] = updatedChofer;
                // Return a new array with modified user
                return {
                    choferes: [...prevState.choferes],
                };
            });
        this.setState({
            mychofer: []
        });
    }
    handleOnDeleteChofer = (deletedChofer) => {
        this.setState(
            prevState => ({
                choferes: prevState.choferes.filter(
                    chofer => chofer.id !== deletedChofer.id),
            }));
    };
    handleCreateChofer = (chofer) => {
        this.setState(prevState => ({
            choferes: [...prevState.choferes, chofer],
        }));
    };
    handleOnGetChofer = (chofer) => {
        this.setState({
            mychofer: chofer
        });
        this.render();
    };


    componentDidMount() {
        ChoferService.getAllChoferes().then(response => {
            this.setState({choferes: response.data});
        });
        TaxisService.getAllTaxis().then(response => {
            this.setState({taxis: response.data});
        });
    }
}

export default ChoferIndex;