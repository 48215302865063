import React from 'react';
import TaxisService from "./services/TaxisService";
import {DEBUG_MODE} from "../../../config/api";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faTrash, faCarAlt} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';


class TaxisTable extends React.Component {
    handleChangeStatus = (event) => {
        const data = event;
        console.log(data);
        const {id} = data;
        const status = data.status === 'ACTIVO' ? 'INACTIVO' : 'ACTIVO';
        TaxisService.updateTaxiStatus(id, status).then((response) => {
            const taxi = response.data;
            if (DEBUG_MODE)
                console.log(taxi);
            const {status, marca, modelo} = taxi;
            toast.success(`Taxi ${marca} ${modelo} ha quedado ${status} `);
            this.props.onUpdateTaxiStatus(taxi);

        }).catch((response) => {
            toast.error(`No se ha podido actualiza el estado del taxi`);
            if (DEBUG_MODE)
                console.error(response);
        });
    };
    handleDeleteTaxi = (event) => {
        const data = event;
        TaxisService.deleteTaxi(data).then((response) => {
            const taxi = response.data;
            if (DEBUG_MODE)
                console.log(taxi);
            const {marca, modelo} = taxi;
            toast.warn(`Taxi ${marca}  ${modelo} eliminado.`);
            this.props.onDeleteTaxi(taxi);
        }).catch((response) => {
            toast.error(`No se ha podido obtener los datos del taxi`);
            if (DEBUG_MODE)
                console.error(response);
        });
    }
    handleEditTaxi = (event) => {
        const data = event;
        if (data !== null) {
            TaxisService.getTaxi(data).then((response) => {
                const taxi = response.data;
                this.setState({taxi});
                if (DEBUG_MODE)
                    console.log(taxi);
                const {marca, modelo} = taxi;
                toast.success(`Taxi ${marca}  ${modelo} `);
                this.props.onGetTaxi(taxi);
            }).catch((response) => {
                toast.error(`No se ha podido obtener los datos del taxi`);
                if (DEBUG_MODE)
                    console.error(response);
            });
        } else {
            const taxi = [];
            this.props.onGetTaxi(taxi);
        }
    };

    render() {
        const {taxis} = this.props;
        console.log(taxis);
        
        return (
            <div>
                <h1>Lista de Taxis <button className="btn btn-primary float-right"
                                           onClick={() => this.handleEditTaxi(null)}>Nuevo Taxi</button></h1>
                <div className="table-responsive">
                    <table className="table">
                        <thead className="thead-dark">
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col"/>
                            <th scope="col">Marca</th>
                            <th scope="col">Modelo</th>
                            <th scope="col">Chofer asignado</th>
                            <th scope="col">Comentarios</th>
                            <th scope="col">Status</th>
                            <th scope="col"/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            
                            taxis.map(taxi =>
                                (
                                    <tr key={taxi.id.toString()}>
                                        <td>{taxi.id.toString()}</td>
                                        <td><img src={taxi.foto_taxi} alt="foto_taxi" className="img-fluid thumb"/></td>
                                        <td>{taxi.marca}</td>
                                        <td>{taxi.modelo}</td>
                                        <td>{taxi.chofer}</td>
                                        <td>{taxi.comentarios}</td>
                                        <td>
                                            <button className="btn btn-info"
                                                    onClick={() => this.handleChangeStatus(taxi)}>{taxi.status}</button>
                                        </td>
                                        <td>
                                            <button className="btn btn-primary"
                                                    onClick={() => this.handleEditTaxi(taxi.id)}>
                                                <FontAwesomeIcon icon={faEdit}/>
                                            </button>
                                            <button className="btn btn-danger"
                                                    onClick={() => this.handleDeleteTaxi(taxi.id)}>
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </button>
                                            <Link
                                                className="mt-1 btn btn-primary"
                                                to={`${process.env.PUBLIC_URL}/renta?id_taxi=${taxi.id}&id_chofer=${taxi.id_chofer}`}>
                                                <FontAwesomeIcon icon={faCarAlt}/>
                                            </Link>
                                        </td>
                                    </tr>
                                ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default TaxisTable;