import React, { Component } from 'react';

export default class FormKilometraje extends Component {

    constructor(props){
        super(props);
        this.props = props;
    }

    render(){
        return (
            <div className={this.props.estilo}>
                <div className="card card-body">
                    <h4>Kilometraje</h4>
                    <form onSubmit={this.props.onSubmitKilometrajeCallback} className="form-group">
                        <label htmlFor="km">Kilometraje:</label>
                        <input
                            className="form-control"
                            type="number"
                            name="kilometraje"
                            placeholder="Kilometraje"
                            min="0"
                            value={this.props.km}
                            onChange={this.props.onChangeCallback}/>
                        <button className="btn btn-primary m-1" type="submit">
                            Guardar
                        </button>
                    </form>
                </div>
            </div>
        );
    }

}