import React, { Component } from 'react';
import AceiteService from './services/AceiteService';
import { toast } from 'react-toastify';

export default class FormAceite extends Component {

    constructor(props){
        super(props);
        this.state = {
            isUpdate: false,
            aceite: {
                id: -1,
                id_taxi: props.id_taxi,
                kilometraje: 0,
                cantidad: 0,
                comentarios: ''
            }
        }
        this.aceiteService = new AceiteService();
    }

    async componentDidMount(){
        try{
            const response = await this.aceiteService.getAceite(
                this.state.aceite.id_taxi
            );
            const aceite = response.data;
            this.setState({
                isUpdate: true,
                aceite: {
                    id: aceite.id,
                    id_taxi: aceite.id_taxi,
                    kilometraje: aceite.kilometraje,
                    cantidad: aceite.cantidad,
                    comentarios: aceite.comentarios
                }
            });
        }catch(error){
            if(error.response){
                const code = error.response.status;
                if(code !== 404){
                    const data = error.response.data;
                    if(data.error){
                        toast.error(data.error);
                    }else{
                        toast.error('Ah ocurrido un error');        
                    }
                }
            }else{
                toast.error('Ah ocurrido un error');
            }
        }
    }

    onChange = e => this.setState({
        aceite: {
            ...this.state.aceite,
            [e.target.name]: e.target.value
        }
    });

    onSubmit = async e => {
        e.preventDefault();        
        try{
            if(this.state.isUpdate){
                await this.aceiteService.update(this.state.aceite.id, this.state.aceite);
                toast.success('Cambio de aceite actualizado');
            }else{
                const response = await this.aceiteService.create(this.state.aceite);
                const aceite = response.data;
                toast.success('Cambio de aceite creado con éxito');
                this.setState({
                    isUpdate: true,
                    aceite: {
                        ...this.state.aceite,
                        id: aceite.id
                    }
                });
            }
        }catch(error){
            if(error.response){
                const data = error.response.data;
                if(data.error){
                    toast.error(data.error);
                }else{
                    toast.error('Ah ocurrido un error');        
                }
            }else{
                toast.error('Ah ocurrido un error');
            }
        }
    }

    onClick = async () => {
        try{
            await this.aceiteService.delete(this.state.aceite.id);
            toast.success('Cambio de aceite eliminado con éxito');
            this.setState({
                isUpdate: false,
                aceite: {
                    id: -1,
                    id_taxi: this.props.id_taxi,
                    kilometraje: 0,
                    cantidad: 0,
                    comentarios: ''
                }
            });
        }catch(error){
            if(error.response){
                const data = error.response.data;
                if(data.error){
                    toast.error(data.error);
                }else{
                    toast.error('Ah ocurrido un error');        
                }
            }else{
                toast.error('Ah ocurrido un error');
            }
        }
    }

    render(){
        return (
            <div className="card card-body">
                <h3>Cambio de aceite</h3>
                <form onSubmit={this.onSubmit} className="form-group">
                    <label>Kilometraje:</label>
                    <input
                        className="form-control"
                        onChange={this.onChange}
                        type="number"
                        name="kilometraje"
                        value={this.state.aceite.kilometraje}
                    />
                    <label>Comentarios:</label>
                    <input
                        className="form-control"
                        placeholder="Comentarios"
                        onChange={this.onChange}
                        type="text"
                        name="comentarios"
                        value={this.state.aceite.comentarios}
                    />
                    <label>Cantidad:</label>
                    <input
                        className="form-control"
                        onChange={this.onChange}
                        type="number"
                        name="cantidad"
                        value={this.state.aceite.cantidad}
                    />
                    <input
                        className="btn btn-primary"
                        type="submit"
                        value="Registrar"
                    />
                    <button
                        onClick={this.onClick}
                        type="button"
                        className="btn btn-danger">
                        Eliminar
                    </button>
                </form>
            </div>
        );
    }

}