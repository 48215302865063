import axios from "axios/index";
import {API_BASE_URL, SESSION_KEY} from "../../../../config/api";
import SessionStorageService from "../../../auth/SessionStorageService";

class ChoferService {
    static createHeaders(subirImagen = false) {

        const headers = {'Content-Type': 'application/json'};

        const userData = SessionStorageService.get(SESSION_KEY);
        if (!!userData && typeof userData.api_token === 'string') {
            headers['Authorization'] = userData['api_token'];
        }

        return headers;
    }


    static getAllChoferes() {
        const url = `${API_BASE_URL}/choferes`;

        return axios.get(url, {
            headers: this.createHeaders(),
            data: {},
        })
    }

    static createChofer(data) {
        const url = `${API_BASE_URL}/choferes`;
        return axios.post(url, data, {
            headers: this.createHeaders(true),

        });
    }

    static updateChoferStatus(id, status) {
        const url = `${API_BASE_URL}/choferes/${id}/status`;
        return axios.patch(url, {
            status: status,
        }, {
            headers: this.createHeaders(),

        });
    }

    static updateChofer(data, id) {
        const url = `${API_BASE_URL}/choferes/${id}`;
        return axios.put(url, data, {
            headers: this.createHeaders(),
        });
    }

    static getChofer(data) {
        const url = `${API_BASE_URL}/choferes/${data}`;

        return axios.get(url, {
            headers: this.createHeaders(),
            data: {}
        });

    }

    static deleteChofer(chofer) {
        const url = `${API_BASE_URL}/choferes/${chofer}`;
        return axios.request({
            url: url,
            method: 'delete',
            headers: this.createHeaders(),
            data: {},// Important: keep data empty so the header "Content-Type" is not removed
        });
    }
}

export default ChoferService;