import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import AceiteService from './services/AceiteService';
import { toast } from 'react-toastify';

export default class TableAceites extends Component {

    constructor(props){
        super(props);
        this.aceiteService = new AceiteService();
        this.state = {
            cambios: []
        }
    }

    async componentDidMount(){
        try{
            const response = await this.aceiteService.getAceites();
            const cambios = response.data;
            this.setState({
                cambios: cambios
            });
        }catch(error){
            if(error.response){
                const data = error.response.data;
                if(data.error){
                    toast.error(data.error);
                }else{
                    toast.error('Ah ocurrido un error');        
                }
            }else{
                toast.error('Ah ocurrido un error');
            }
        }
    }

    render(){
        return (
            <div className="card card-body">
                <h4 className="text-center">
                    <p>Vehiculos que requieren cambio de aceite: {this.state.cambios.length}</p>
                </h4>
                <div className="form-group">
                    <div className="m-2 text-center">
                        {this.state.cambios.map(cambio => {
                            return (
                                <p key={cambio.taxi}>
                                    <label>{cambio.chofer}</label>
                                    <strong className="ml-1">{"#"+cambio.taxi+" "+cambio.marca+" "+cambio.modelo}</strong>
                                    <Link
                                        className="m-1 btn btn-info"
                                        to={"/aceites/"+cambio.taxi}>
                                        Aceite
                                    </Link>
                                </p>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

}