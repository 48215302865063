const API_BASE_URL = "https://taxisqualo.com/ws"
//const API_BASE_URL = "http://localhost:8000/ws";
export {API_BASE_URL};
const LOCAL_IMAGE_URL = "/";
export {LOCAL_IMAGE_URL}
const STATIC_URL = "https://taxisqualo.com";
//const STATIC_URL = "http://localhost:3000";
export {STATIC_URL}
const SESSION_KEY = "qualo-user";
export {SESSION_KEY};
const DEBUG_MODE = false;
export {DEBUG_MODE};
const MAPS_API_KEY = "AIzaSyA-FQhgHXTrCw0Hl9ZODanV7L6e353q5j4";
export {MAPS_API_KEY};