import axios from "axios/index";
import {API_BASE_URL, SESSION_KEY} from "../../../../config/api";
import SessionStorageService from "../../../auth/SessionStorageService";

class ConfiguracionService {
    static createHeaders() {
        const headers = {'Content-Type': 'application/json'};
        const userData = SessionStorageService.get(SESSION_KEY);
        if (!!userData && typeof userData.api_token === 'string') {
            headers['Authorization'] = userData['api_token'];
        }

        return headers;
    }


    static getAllConfiguracion() {
        const url = `${API_BASE_URL}/configuracion`;

        return axios.get(url, {
            headers: this.createHeaders(),
            data: {},
        })
    }

    static updateConfiguracion(data, id) {
        const url = `${API_BASE_URL}/configuracion/${id}`;

        return axios.put(url, data, {
            headers: this.createHeaders(),

        });
    }

    static getConfiguracion(data) {
        const url = `${API_BASE_URL}/configuracion/${data}`;

        return axios.get(url, {
            headers: this.createHeaders(),
            data: {},
        })
    }
}

export default ConfiguracionService;