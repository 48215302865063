import axios from "axios/index";
import {API_BASE_URL, SESSION_KEY} from "../../../../config/api";
import SessionStorageService from "../../../auth/SessionStorageService";

class UsersService {
    static createHeaders() {
        const headers = {'Content-Type': 'application/json'};
        const userData = SessionStorageService.get(SESSION_KEY);
        if (!!userData && typeof userData.api_token === 'string') {
            headers['Authorization'] = userData['api_token'];
        }

        return headers;
    }

    static getAllUsers() {
        const url = `${API_BASE_URL}/usuarios`;

        return axios.get(url, {
            headers: this.createHeaders(),
            data: {},
        })
    }

    static getUser(id_user) {
        const url = `${API_BASE_URL}/usuarios/${id_user}`;

        return axios.get(url, {
            headers: this.createHeaders(),
            data: {},
        })
    }

    static createUser(data) {
        const url = `${API_BASE_URL}/usuarios`;
        return axios.post(url, data, {
            headers: this.createHeaders(),
        });
    }

    static updateUser(data, id_user) {
        const url = `${API_BASE_URL}/usuarios/${id_user}`;
        return axios.put(url, data, {
            headers: this.createHeaders(),
        });
    }

    static updateUserStatus(id, status) {
        const url = `${API_BASE_URL}/usuarios/${id}/status`;
        return axios.patch(url, {status: status,}, {
            headers: this.createHeaders(),
        });

    }

    static deleteUser(user) {
        const url = `${API_BASE_URL}/usuarios/${user}`;
        return axios.request({
            url: url,
            method: 'delete',
            headers: this.createHeaders(),
            data: {},// Important: keep data empty so the header "Content-Type" is not removed
        });
    }
}

export default UsersService;