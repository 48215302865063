import React from 'react';

class ReportesIndex extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="row my-3">
                    <div className="col-md-12">
                        <h1>Reportes</h1>

                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-md-12">
                        <div className="row text-center">
                            <div className="col-md-4">
                                <button className="btn btn-primary btn-lg">Reporte 1</button>
                            </div>
                            <div className="col-md-4">
                                <button className="btn btn-info btn-lg">Reporte 2</button>
                            </div>
                            <div className="col-md-4">
                                <button className="btn btn-warning btn-lg">Reporte 3</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReportesIndex;