import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap';
import FormRenta from './FormRenta';
import FormKilometraje from './FormKilometraje';

export default class SimpleModal extends Component {
    
    render() {
        let component;
        if(this.props.renta.id){
            component = (
                <FormRenta
                    index={this.props.renta?this.props.renta.index:-1}
                    title="Renta"
                    onSubmitRentaCallback={this.props.onSubmitRenta}
                    onChangeCallback={this.props.onChange}
                    cuota={this.props.renta?this.props.renta.cuota_editable:0}
                    deuda={this.props.renta?this.props.renta.deuda:0}
                    pago={this.props.renta?this.props.renta.pago:0}
                    faltante={this.props.renta?this.props.renta.faltante:0}/>
            );
        }
        return (
            <>      
                <Modal show={this.props.show} onHide={this.props.handleClose}>
                    <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                        {component}
                        <FormKilometraje
                            onSubmitKilometrajeCallback={this.props.onSubmitKilometraje}
                            km={this.props.kilometraje.km}
                            onChangeCallback={this.props.onChange}
                            />
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.handleClose}>
                        Cerrar
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

}