import axios from "axios/index";
import {API_BASE_URL, SESSION_KEY} from "../../../../config/api";
import SessionStorageService from "../../../auth/SessionStorageService";

class CarreraService {
    static createHeaders() {
        const headers = {'Content-Type': 'application/json'};
        const userData = SessionStorageService.get(SESSION_KEY);
        if (!!userData && typeof userData.api_token === 'string') {
            headers['Authorization'] = userData['api_token'];
        }

        return headers;
    }

    static getAllCarreras(data) {
        const url = `${API_BASE_URL}/carreras`;
        return axios.get(url , {
            headers: this.createHeaders(),
            data: {},
        })
    }

    static getCarrera(data) {
        const url = `${API_BASE_URL}/carreras/${data}`;

        return axios.get(url, {
            headers: this.createHeaders(),
            data: {}
        });

    }

    static createCarrera(data) {
        const url = `${API_BASE_URL}/carreras`;

        return axios.post(url, data, {
            headers: this.createHeaders(),

        });
    }

    static updateCarreraStatus(data) {
        const url = `${API_BASE_URL}/carreras`;

        return axios.patch(url, {
            id: data.get('id'),
            status: data.get('status'),
        }, {
            headers: this.createHeaders(),

        });
    }
    static getFilterCarreras(data) {
        const url = `${API_BASE_URL}/carrerasfiltro?fecha1=${data.fecha1}&fecha2=${data.fecha2}&chofer=${data.filtro_chofer}&taxi=${data.filtro_taxi}&estado=${data.filtro_estado}`;
        return axios.get(url, {
            headers: this.createHeaders(),
            data: {}
        });
    }

}

export default CarreraService;