import React from 'react';
import {format} from "date-fns";

class CarreraFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: []
        };
    }

    handleChangeFilter = (value) => {
        const data = this.state.filter;
        data[value['inputName']] = value['inputValue'];
        this.setState({
            filter: data
        });
        this.props.onUpdateFilter(data);

    }


    render() {
        const {filter} = this.state;
        const {choferes,taxis} = this.props;
        if (!filter.fecha1) {
            filter.fecha1 = new Date();

            filter.fecha1 = format(filter.fecha1, "yyyy-MM-dd")
        }
        if (!filter.fecha2) {
            filter.fecha2 = new Date();

            filter.fecha2 = format(filter.fecha2, "yyyy-MM-dd")
        }
        if(!filter.filtro_chofer)
        {
            filter.filtro_chofer = "*";
        }
        if(!filter.filtro_taxi)
        {
            filter.filtro_taxi = "*";
        }
        if(!filter.filtro_estado)
        {
            filter.filtro_estado = "*";
        }
        return (
            <div className="form-inline">
                <div className="input-group mb-2 mr-sm-2 daterange">
                    <label className="sr-only" htmlFor="fecha1">Rango de Fecha</label>
                    <input type="date" className="form-control" id="fecha1" name="fecha1"
                           value={filter.fecha1} onChange={e => this.handleChangeFilter({
                        "inputName": e.target.name,
                        "inputValue": e.target.value,
                        "extra": e.target
                    })}/>
                    <div className="input-group-text">a</div>
                    <input type="date" className="form-control " id="fecha2" name="fecha2"
                           value={filter.fecha2} onChange={e => this.handleChangeFilter({
                        "inputName": e.target.name,
                        "inputValue": e.target.value,
                        "extra": e.target
                    })}/>

                </div>
                <div className="input-group">
                    <label className="sr-only" htmlFor="filtro_chofer">Chofer</label>
                    <select className="form-control" id="filtro_chofer" name="filtro_chofer"
                            value={filter.filtro_chofer ? filter.filtro_chofer : "*"}
                            onChange={e => this.handleChangeFilter({
                                "inputName": e.target.name,
                                "inputValue": e.target.value,
                                "extra": e.target
                            })}>
                        <option value="*">TODOS LOS CHOFERES</option>
                        {
                            choferes.map(chofer => (
                                <option key={chofer.id} value={chofer.id}>{chofer.nombre}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="input-group">
                    <label className="sr-only" htmlFor="filtro_taxi">Taxi</label>
                    <select className="form-control" id="filtro_taxi" name="filtro_taxi"
                            value={filter.filtro_taxi ? filter.filtro_taxi : "*"}
                            onChange={e => this.handleChangeFilter({
                                "inputName": e.target.name,
                                "inputValue": e.target.value,
                                "extra": e.target
                            })}>
                        <option value="*">TODOS LOS TAXIS</option>
                        {
                            taxis.map(taxi => (
                                <option key={taxi.id} value={taxi.id}>#{taxi.id} - {taxi.marca} {taxi.modelo}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="input-group">
                    <label className="sr-only" htmlFor="filtro_estado">Status</label>
                    <select className="form-control" id="filtro_estado" name="filtro_estado"
                            value={filter.filtro_estado ? filter.filtro_estado : "*"}
                            onChange={e => this.handleChangeFilter({
                                "inputName": e.target.name,
                                "inputValue": e.target.value,
                                "extra": e.target
                            })}>
                        <option value="*">TODOS LOS STATUS</option>
                        <option value="SOLICITADA">SOLICITADA</option>
                        <option value="TAXISTA_ASIGNADO">TAXISTA_ASIGNADO</option>
                        <option value="TAXISTA_EN_MARCHA">TAXISTA_EN_MARCHA</option>
                        <option value="CARRERA_INICIADA">CARRERA_INICIADA</option>
                        <option value="CARRERA_FINALIZADA">CARRERA_FINALIZADA</option>
                        <option value="CARRERA_CANCELADA">CARRERA_CANCELADA</option>
                    </select>
                </div>
            </div>
        );
    }
}

export default CarreraFilter;