import React from 'react';
import {NavLink} from 'react-router-dom';
import AuthenticationService from "../../auth/AuthenticationService";


class MenuAdministrador extends React.Component {
    render() {
        const {isUserAuthenticated, userRole} = this.props;
        return (
            <div className="">
                {isUserAuthenticated &&
                <ul className="navbar-nav">

                    {
                        AuthenticationService.hasRole("Usuario") &&
                        <li className="nav-item">
                            <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/rentas`}>
                                Rentas
                            </NavLink>
                        </li>
                    }
                    {AuthenticationService.hasRole("Usuario") &&
                    <li className="nav-item">
                        <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/clientes`}>Clientes</NavLink>
                    </li>
                    }
                    {AuthenticationService.hasRole("Usuario") &&
                    <li className="nav-item">
                        <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/usuarios`}>Usuarios
                        </NavLink>
                    </li>
                    }
                    {AuthenticationService.hasRole("Usuario") &&
                    <li className="nav-item">
                        <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/taxis`}>Taxis
                        </NavLink>
                    </li>
                    }
                    {AuthenticationService.hasRole("Usuario") &&
                    <li className="nav-item">
                        <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/choferes`}>Choferes
                        </NavLink>
                    </li>
                    }
                    <li className="nav-item">
                        <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/carreras`}>Carreras
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/mapa`}>Mapa
                        </NavLink>
                    </li>
                    {AuthenticationService.hasRole("Usuario") &&
                    <li className="nav-item">
                        <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/inventario`}>Inventario
                        </NavLink>
                    </li>
                    }
                    {AuthenticationService.hasRole("Usuario") &&
                    <li className="nav-item">
                        <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/reportes`}>Reportes
                        </NavLink>
                    </li>
                    }
                    {AuthenticationService.hasRole("Usuario") &&
                    <li className="nav-item">
                        <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/configuracion`}>Configuración
                        </NavLink>
                    </li>
                    }
                    {AuthenticationService.hasRole("Chofer") &&
                    <li className="nav-item">
                        <NavLink className="nav-link" to={`https://taxisqualo.com/app/chofer.apk`}>Descargar Aplicación
                        </NavLink>
                    </li>
                    }

                    <button className="nav-item nav-link btn btn-danger btn-lg" style={{color: "white"}}
                            aria-disabled="true" onClick={this.handleLogout}>Logout
                    </button>


                </ul>
                }
            </div>

        );
    }

}

export default MenuAdministrador;