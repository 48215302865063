import React from 'react';
import ChoferService from "./services/ChoferService";
import {DEBUG_MODE} from "../../../config/api";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons'


class ChoferTable extends React.Component {
    handleChangeStatus = (event) => {
        const data = event;
        const {id} = data;
        const status = data.status === 'ACTIVO' ? 'INACTIVO' : 'ACTIVO';
        ChoferService.updateChoferStatus(id, status).then((response) => {
            const chofer = response.data;
            if (DEBUG_MODE)
                console.log(chofer);
            const {nombre} = chofer;
            toast.success(`Chofer ${nombre} ha quedado ${status} `);
            this.props.onUpdateChoferStatus(chofer);

        }).catch((response) => {
            toast.error(`No se ha podido ${status}R el chofer`);
            if (DEBUG_MODE)
                console.error(response);
        });
    };
    handleEditChofer = (event) => {
        const data = event;
        if (data !== null) {
            ChoferService.getChofer(data).then((response) => {
                const chofer = response.data;
                if (DEBUG_MODE)
                    console.log(chofer);
                this.props.onGetChofer(chofer);

            }).catch((response) => {
                toast.error(`No se ha podido editar el chofer`);
                if (DEBUG_MODE)
                    console.error(response);
            });
        } else {
            const chofer = [];
            this.props.onGetChofer(chofer);
        }
    };
    handleDeleteChofer = (event) => {
        const data = event;
        ChoferService.deleteChofer(data).then((response) => {
            const chofer = response.data;
            if (DEBUG_MODE)
                console.log(chofer);
            const {nombre} = chofer;
            toast.warn(`Chofer ${nombre} eliminado.`);
            this.props.onDeleteChofer(chofer);
        }).catch((response) => {
            toast.error(`No se ha podido obtener los datos del chofer`);
            if (DEBUG_MODE)
                console.error(response);
        });
    }

    render() {
        const {choferes} = this.props;
        return (
            <div>
                <h1>Lista de Choferes
                    <button className="btn btn-primary float-right"
                            onClick={() => this.handleEditChofer(null)}>Nuevo Chofer
                    </button>
                </h1>
                <div className="table-responsive">
                    <table className="table">
                        <thead className="thead-dark">
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col"/>
                            <th scope="col">Taxi Asignado</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Usuario</th>
                            <th scope="col">Dirección</th>
                            <th scope="col">Telefono</th>
                            <th scope="col">Celular</th>
                            <th scope="col">Exp. Licencia</th>
                            <th scope="col">Status</th>
                            <th scope="col"/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            choferes.map(chofer => (
                                <tr key={chofer.id.toString()}>
                                    <td>{chofer.id.toString()}</td>
                                    <td><img src={chofer.foto_chofer} className="img-fluid thumb"
                                             alt="foto_chofer"/></td>
                                    <td>#{chofer.id_taxi} - <small>{chofer.marca} {chofer.modelo}</small></td>
                                    <td>{chofer.nombre}</td>
                                    <td>{chofer.usuario}</td>
                                    <td>{chofer.direccion}</td>
                                    <td>{chofer.telefono}</td>
                                    <td>{chofer.celular}</td>
                                    <td>{chofer.exp_licencia}</td>
                                    <td>
                                        <button className="btn btn-info"
                                                onClick={() => this.handleChangeStatus(chofer)}>{chofer.status}</button>
                                    </td>
                                    <td>
                                        <button className="btn btn-primary"
                                                onClick={() => this.handleEditChofer(chofer.id)}>
                                            <FontAwesomeIcon icon={faEdit}/>
                                        </button>
                                        <button className="btn btn-danger"
                                                onClick={() => this.handleDeleteChofer(chofer.id)}>
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default ChoferTable;