import React from 'react';
import ClientService from "./services/ClientService";
import {DEBUG_MODE} from "../../../config/api";
import {toast} from "react-toastify/index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";

class ClientTable extends React.Component {
    handleChangeStatus = (event) => {
        event.preventDefault();
        const data = event;
        console.log(data);
        ClientService.updateUserStatus(data).then((response) => {
            const client = response.data;
            if (DEBUG_MODE)
                console.log(client);
            const {name, correo} = client;
            toast.success(`Cliente ${name} creado exitosamente con el correo ${correo} `);
            this.props.onCreateUser(client);

        }).catch((response) => {
            toast.error(`No se ha podido crear el cliente`);
            if (DEBUG_MODE)
                console.error(response);
        });
    };

    render() {
        const {chofer, clients} = this.props;
        return (
            <div>
                <h1>Lista de Clientes</h1>
                <div className="table-responsive">
                    <table className="table">
                        <thead className="thead-dark">
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Correo</th>
                            <th scope="col">Activo</th>
                            <th scope="col">Comentarios</th>
                            <th scope="col" />
                        </tr>
                        </thead>
                        <tbody>
                        {
                            clients.map(client => (
                                <tr key={client.id.toString()}>
                                    <td>{client.id.toString()}</td>
                                    <td>{client.nombre}</td>
                                    <td>{client.correo}</td>
                                    <td>
                                        <button className="btn btn-info"
                                                onClick={() => this.handleChangeStatus(chofer)}>{chofer.status}</button>
                                    </td>
                                    <td>{client.comentarios}</td>
                                    <td>
                                        <button className="btn btn-primary"
                                                onClick={() => this.handleEditChofer(chofer.id)}>
                                            <FontAwesomeIcon icon={faEdit}/>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default ClientTable;