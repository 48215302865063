import React from 'react';
import imagen from '../../../images/banners/banner1.jpg';
import choferes from '../../../images/banners/banner3.jpg';
import logo from '../../../logo.png';
import Image from "react-bootstrap/cjs/Image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCertificate, faIdBadge} from '@fortawesome/free-solid-svg-icons'
import Alert from "react-bootstrap/cjs/Alert";

class ContratacionesIndex extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-6 pt-0 pl-5 pr-5">
                        <Image src={logo} fluid />
                        <p className="text-justify">Somos una empresa fundada en la ciudad de Tampico, Tamaulipas en el
                            año 2004, con el objetivo de brindar un servicio seguro, confiable y económico de
                            transporte. Tratando de entender en todo momento las necesidades de nuestros clientes. </p>
                        <p className="text-center bg-warning"><b>17 ANIVERSARIO</b></p>

                        <p className="text-justify">En el 2020 cumplimos 17 años de servicio de taxis, las 24 horas, los
                            365 días del año.
                            A lo lago de este tiempo hemos aprendido a conocer y comprender las necesidades de
                            transporte de los tamaulipecos que habitan la zona conurbada de Tampico, Madero y
                            Altamira.</p>
                        <p className="text-justify">Agradecidos de su preferencia queremos seguir innovando y creciendo
                            juntos en este bello
                            paisaje del sur de nuestro estado.</p>
                    </div>
                    <div className="col-md-6">
                        <Image src={imagen} rounded fluid/>
                    </div>
                </div>
                <Alert variant="primary" className="text-center font-weight-bold">
                    <Alert.Heading>TaxisQualo</Alert.Heading>
                    <p>
                        Tú ciudad, tú taxi de confianza
                    </p>
                </Alert>

                <div className="row mt-5">
                    <div className="col-md-6">
                        <div>
                            <Image src={choferes} rounded fluid/>

                        </div>
                    </div>
                    <div className="col-md-6 p-5">
                        <h4 className="uk-text-center uk-text-black text-center"> NUESTRO PERSONAL</h4>
                        <p className="text-justify">Nuestros choferes cuentan con una amplia experiencia y capacitación
                            que
                            dan garantía de la calidad en el servicio brindado a nuestros clientes.</p>
                        <div className="row p-2">
                            <div className="col-md-6 text-center">
                                <div className="mb-3"><FontAwesomeIcon icon={faIdBadge} size="lg"/></div>
                                <h4>Licencia</h4>
                                <p>Nuestros choferes tienen sus documentos vigentes</p>
                            </div>
                            <div className="col-md-6 text-center">
                                <div className="mb-3"><FontAwesomeIcon icon={faCertificate} size="lg"/></div>
                                <h4>Seguro </h4>
                                <p>Todas nuestras unidades se encuentran aseguradas</p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

export default ContratacionesIndex;