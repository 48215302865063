import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class RentaRow extends Component {

    constructor(props){
        super(props);
        this.props = props;
    }

    render(){
        return (
            <tr>
                <td>{this.props.renta.id_taxi}</td>
                <td>{this.props.renta.chofer.nombre}</td>
                <td>{"$"+this.props.renta.cuota_editable}</td>
                <td>{"$"+this.props.renta.deuda_total}</td>
                <td>{"$"+this.props.renta.pago}</td>
                <td>
                    <Link
                        className="btn btn-primary"
                        to={"renta?id_taxi="+this.props.renta.id_taxi+"&id_renta="+this.props.renta.id}>
                        Ver
                    </Link>
                    <Link className="btn btn-secondary" to={"calendario/"+this.props.renta.chofer.id}>
                        Calendario
                    </Link>
                    <Link
                        className="mt-1 btn btn-info"
                        to={"/aceites/"+this.props.renta.id_taxi}>
                        Aceite
                    </Link>
                </td>
            </tr>
        );
    }

}