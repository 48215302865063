import React from 'react';
import CarreraService from "./services/CarreraService";
import {DEBUG_MODE} from "../../../config/api";
import {toast} from "react-toastify";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";

class CarreraTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: [],
            mycarrera: [],
            error: null,
        };
    }

    handleChangeStatus = (event) => {
        event.preventDefault();
        const data = event;
        console.log(data);
        CarreraService.updateCarreraStatus(data).then((response) => {
            const carrera = response.data;
            if (DEBUG_MODE)
                console.log(carrera);
            const {name} = carrera;
            toast.success(`Carrera ${name} creado exitosamente `);
            this.props.onCreateCarrera(carrera);

        }).catch((response) => {
            toast.error(`No se ha podido crear la carrera`);
            if (DEBUG_MODE)
                console.error(response);
        });
    };
    handleEditCarrera = (event) => {
        const data = event;
        if (data !== null) {
            CarreraService.getCarrera(data).then((response) => {
                const carrera = response.data;
                if (DEBUG_MODE)
                    console.log(carrera);
                this.props.onGetCarrera(carrera);

            }).catch((response) => {
                toast.error(`No se ha podido crear el chofer`);
                if (DEBUG_MODE)
                    console.error(response);
            });
        } else {
            const carrera = [];
            this.props.onGetCarrera(carrera);
        }
    };


    render() {
        const {carreras} = this.props;
        const metadata = carreras.metadata;
        const tabla = carreras.tabla;
        return (
            <div>

                <button className="btn btn-primary float-right"
                        onClick={() => this.handleEditCarrera(null)}>Nuevo Carrera
                </button>

                <div className="table-responsive">
                    <div>Mostrando {metadata ? metadata.mostrando : "1"} de {metadata ? metadata.total : "0"}</div>
                    <table className="table">
                        <thead className="thead-dark">
                        <tr>
                            <th>#</th>
                            <th scope="col">Id</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Cliente</th>
                            <th scope="col">Taxi</th>
                            <th scope="col">Chofer</th>
                            <th scope="col">Origen</th>
                            <th scope="col">Destino</th>
                            <th scope="col">Comentarios</th>
                            <th scope="col">Status</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            tabla &&
                            tabla.map(carrera => (
                                <tr key={carrera.id.toString()}>
                                    <td>{carrera.count.toString()}</td>
                                    <td>{carrera.id.toString()}</td>
                                    <td>{carrera.fecha_solicitud}</td>
                                    <td>{carrera.cliente.nombre}</td>
                                    <td>{carrera.taxi && "#"+carrera.taxi.id}</td>
                                    <td>{carrera.chofer && carrera.chofer.nombre}</td>
                                    <td>{carrera.origen}</td>
                                    <td>{carrera.destino}</td>
                                    <td>{carrera.comentarios}</td>
                                    <td>
                                        {carrera.estado}
                                    </td>
                                    <td>
                                        <button className="btn btn-primary"
                                                onClick={() => this.handleEditCarrera(carrera.id)}><FontAwesomeIcon
                                            icon={faEdit}/></button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                    {
                        metadata ?
                            <ul className="pagination">
                                <li className="page-item disabled">
                                    <a className="page-link" href={metadata ? metadata.anterior : ""}
                                       tabIndex="-1">Anterior</a>
                                </li>
                                <li className="page-item active">
                                    <a className="page-link"
                                       href={metadata ? metadata.actual : ""}>{metadata ? metadata.actual : ""} <span
                                        className="sr-only">(actual)</span></a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href={metadata ? metadata.siguiente : ""}>Siguiente</a>
                                </li>
                            </ul>
                            : ""
                    }
                </div>
            </div>
        );
    }
}

export default CarreraTable;