import React from 'react';
import {Carousel} from 'react-bootstrap';
import banner1 from '../../../images/banners/banner1.jpg';
import banner2 from '../../../images/banners/banner2.jpg';
import banner3 from '../../../images/banners/banner3.jpg';

class CarouselIndex extends React.Component {
    render() {
        return (

            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={banner3}
                        alt="First slide"
                    />
                    <Carousel.Caption className="caption-bottom">
                        <div className="p-3 carousel-caption-text ">
                            <h3>Te ofrecemos una de las mejores opciones de movilidad</h3>
                            <p>Tampico - Madero - Altamira</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={banner1}
                        alt="Third slide"
                    />

                    <Carousel.Caption className="caption-left">
                        <div className="p-3 carousel-caption-text ">
                            <h3>Te ofrecemos una de las mejores opciones de movilidad</h3>
                            <p>Tampico - Madero - Altamira</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={banner2}
                        alt="Third slide"
                    />

                    <Carousel.Caption className="caption-right">
                        <div className="p-3 carousel-caption-text float-right ">
                            <h3>Te ofrecemos una de las mejores opciones de movilidad</h3>
                            <p>Tampico - Madero - Altamira</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        );
    }
}

export default CarouselIndex;