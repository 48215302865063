import React, {Component} from 'react';
import './App.css';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./components/common/theme/Footer";
import Router from "./routing/Router"
import taxistripe from "./images/taxi-stripe.jpg";


class App extends Component {
    render() {
        return (
            <div className="App">
                <Router/>
                <img className="d-block w-100" src={taxistripe} alt="Third slide"/>
                <Footer/>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
            </div>

        );
    }
}

export default App;
