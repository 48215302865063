import axios from "axios/index";
import {API_BASE_URL, SESSION_KEY} from "../../../../config/api";
import SessionStorageService from "../../../auth/SessionStorageService";

class TaxisService {
    static createHeaders() {
        const headers = {'Content-Type': 'application/json'};
        const userData = SessionStorageService.get(SESSION_KEY);
        if (!!userData && typeof userData.api_token === 'string') {
            headers['Authorization'] = userData['api_token'];
        }
        return headers;
    }


    static getAllTaxis() {
        const url = `${API_BASE_URL}/taxis`;

        return axios.get(url, {
            headers: this.createHeaders(),
            data: {},
        })
    }

    static createTaxi(data) {
        const url = `${API_BASE_URL}/taxis`;
        return axios.post(url, data, {
            headers: this.createHeaders(),
        });
    }

    static updateTaxi(data, id_taxi) {
        //const id_taxi = data.get('id_taxi');
        const url = `${API_BASE_URL}/taxis/${id_taxi}`;
        return axios.put(url, data, {
            headers: this.createHeaders(),
        });
    }

    static updateTaxiStatus(id_taxi, status) {
        const url = `${API_BASE_URL}/taxis/${id_taxi}/status`;
        return axios.patch(url, {
            status: status,
        }, {
            headers: this.createHeaders(),

        });
    }

    static getTaxi(data) {
        const url = `${API_BASE_URL}/taxis/${data}`;

        return axios.get(url, {
            headers: this.createHeaders(),
            data: {}
        });
    }

    static deleteTaxi(taxi) {
        const url = `${API_BASE_URL}/taxis/${taxi}`;
        return axios.request({
            url: url,
            method: 'delete',
            headers: this.createHeaders(),
            data: {},// Important: keep data empty so the header "Content-Type" is not removed
        });
    }

}

export default TaxisService;