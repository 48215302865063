import axios from "axios/index";
import {API_BASE_URL, SESSION_KEY} from "../../../../config/api";
import SessionStorageService from "../../../auth/SessionStorageService";

class ClientService {
    static createHeaders() {
        const headers = {'Content-Type': 'application/json'};
        const userData = SessionStorageService.get(SESSION_KEY);
        if (!!userData && typeof userData.api_token === 'string') {
            headers['Authorization'] = userData['api_token'];
        }

        return headers;
    }

    static getAllClients() {
        const url = `${API_BASE_URL}/clientes`;

        return axios.get(url, {
            headers: this.createHeaders(),
            data: {},
        })
    }

    static createClient(data) {
        const url = `${API_BASE_URL}/clientes`;

        return axios.post(url, {
            nombre: data.get('name'),
            correo: data.get('correo'),
            password: data.get('password'),
            foto_perfil: data.get('foto_perfil'),
            fecha_nac: "2020-01-01",
        }, {
            headers: this.createHeaders(),

        });
    }

    static updateClientStatus(data) {
        const url = `${API_BASE_URL}/clientes`;

        return axios.post(url, {
            id: data.get('id'),
            status: data.get('status'),
        }, {
            headers: this.createHeaders(),

        });
    }
}

export default ClientService;