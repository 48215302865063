import React from 'react';
import UsersService from "./services/UsersService";
import {toast} from 'react-toastify';
import {DEBUG_MODE} from "../../../config/api";

class UsersFormCreate extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            error: null,
            myuser: []
        };
    }

    componentDidUpdate(prevProps) {
        const {myuser} = this.props;
        if (myuser !== prevProps.myuser) {
            myuser.mypassword = myuser.password;
            myuser.password = "**********";
            this.setState({
                myuser: myuser
            });
        }

    }

    handleSubmit = (event) => {
        event.preventDefault();
        const {myuser} = this.state;
        if (!event.target.checkValidity()) {
            if (DEBUG_MODE)
                console.warn('Invalid data!');
            toast.error('Por favor llena todos los campos');
            return;
        }
        var formData = new FormData();
        formData.append("nombre", myuser.nombre);
        formData.append("correo", myuser.correo);
        formData.append("password", myuser.password);
        formData.append("usuario", myuser.usuario);
        if (DEBUG_MODE)
            console.log(formData);
        if (!myuser.id) {
            UsersService.createUser(formData).then((response) => {
                if (response.data.Error) {
                    toast.error(response.data.Error);
                    return;
                }
                const user = response.data;
                if (DEBUG_MODE)
                    console.log(user);
                const {nombre, correo} = user;
                toast.success(`Usuario ${nombre} creado exitosamente con el correo ${correo} `);
                this.props.onCreateUser(user);

            }).catch((error) => {
                const {Error} = error;
                toast.error(`Error ${Error}`);
                if (DEBUG_MODE)
                    console.error(error);
            });
        } else {
            UsersService.updateUser(formData, myuser.id).then((response) => {
                if (response.data.Error) {
                    toast.error(response.data.Error);
                    return;
                }
                const user = response.data;
                if (DEBUG_MODE)
                    console.log(user);
                toast.success(`El Usuario ha sido editado exitosamente`);
                this.props.onUpdateUser(user);

            }).catch((response) => {
                const {error} = response;
                toast.error(`Error ${error}`);
                if (DEBUG_MODE)
                    console.error(response);
            });
        }
    };

    handleChange = (value) => {
        const data = this.state.myuser;
        data[value['inputName']] = value['inputValue'];
        this.setState({
            myuser: data
        });
    }

    render() {
        const {myuser} = this.state;
        return (
            <div className="b-form">
                <h2>Nuevo Usuario</h2>
                <form onSubmit={this.handleSubmit} noValidate>
                    <div className="form-group d-none">
                        <label htmlFor="id">Id</label>
                        <input type="hidden" className="form-control" id="id" name="id" required
                               value={myuser.id && myuser.id}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="nombre">Nombre</label>
                        <input type="text" className="form-control" id="nombre" name="nombre" required
                               value={myuser ? myuser.nombre: ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="usuario">Usuario</label>
                        <input type="text" className="form-control" id="usuario" name="usuario" required
                               value={myuser ? myuser.usuario: ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="correo">Correo electrónico</label>
                        <input type="email" className="form-control" id="correo" name="correo" required
                               value={myuser ? myuser.correo: ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" id="password" name="password" required
                               value={myuser ? myuser.password: ""} onChange={e => this.handleChange({
                            "inputName": e.target.name,
                            "inputValue": e.target.value
                        })}/>
                    </div>
                    {myuser.id &&
                    <button type="submit" className="btn btn-primary">Editar Usuario</button>
                    }
                    {!myuser.id &&
                    <button type="submit" className="btn btn-primary">Registrar Usuario</button>
                    }
                </form>
            </div>
        );
    }
}

export default UsersFormCreate;