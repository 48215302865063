import React from 'react';
import { Component } from 'react';
import AfinacionService from './services/AfinacionService';
import { toast } from 'react-toastify';

export default class FormAfinacion extends Component {

    constructor(props){
        super(props);
        this.state = {
            isUpdate: false,
            afinacion: {
                id: -1,
                id_taxi: props.id_taxi,
                kilometraje: 0,
                comentarios: ''
            }
        }
        this.afinacionService = new AfinacionService();
    }

    async componentDidMount(){
        try{
            const response = await this.afinacionService.getAfinacion(
                this.state.afinacion.id_taxi
            );
            const afinacion = response.data;
            this.setState({
                isUpdate: true,
                afinacion: {
                    id: afinacion.id,
                    id_taxi: afinacion.id_taxi,
                    kilometraje: afinacion.kilometraje,
                    comentarios: afinacion.comentarios
                }
            });
        }catch(error){
            if(error.response){
                const code = error.response.status;
                if(code !== 404){
                    const data = error.response.data;
                    if(data.error){
                        toast.error(data.error);
                    }else{
                        toast.error('Ah ocurrido un error');        
                    }
                }
            }else{
                toast.error('Ah ocurrido un error');
            }
        }
    }

    onChange = e => this.setState({
        afinacion: {
            ...this.state.afinacion,
            [e.target.name]: e.target.value
        }
    });

    onSubmit = async e => {
        e.preventDefault();
        try{
            if(this.state.isUpdate){
                await this.afinacionService.update(this.state.afinacion.id, this.state.afinacion);
                toast.success('Afinación actualizada con éxito');
            }else{
                const response = await this.afinacionService.create(this.state.afinacion);
                const afinacion = response.data;
                toast.success('Afinación creada con éxito');
                this.setState({
                    isUpdate: true,
                    afinacion: {
                        ...this.state.afinacion,
                        id: afinacion.id
                    }
                });
            }
        }catch(error){
            if(error.response){
                const data = error.response.data;
                if(data.error){
                    toast.error(data.error);
                }else{
                    toast.error('Ah ocurrido un error');        
                }
            }else{
                toast.error('Ah ocurrido un error');
            }
        }
    }

    onClick = async () => {        
        try{
            await this.afinacionService.delete(this.state.afinacion.id);
            toast.success('Afinación eliminada con éxito');
            this.setState({
                isUpdate: false,
                afinacion: {
                    id: -1,
                    id_taxi: this.props.id_taxi,
                    kilometraje: 0,
                    comentarios: ''
                }
            });
        }catch(error){
            if(error.response){
                const data = error.response.data;
                if(data.error){
                    toast.error(data.error);
                }else{
                    toast.error('Ah ocurrido un error');        
                }
            }else{
                toast.error('Ah ocurrido un error');
            }
        }
    }

    render(){
        return (
            <div className="card card-body">
                <h3>Afinación</h3>
                <form onSubmit={this.onSubmit} className="form-group">
                    <label>Kilometraje:</label>
                    <input
                        className="form-control"
                        onChange={this.onChange}
                        type="number" 
                        name="kilometraje"
                        value={this.state.afinacion.kilometraje}
                    />
                    <label>Comentarios:</label>
                    <input
                        className="form-control"
                        placeholder="Comentarios"
                        onChange={this.onChange}
                        type="text"
                        name="comentarios"
                        value={this.state.afinacion.comentarios}
                    />
                    <input
                        className="btn btn-primary"
                        type="submit"
                        value="Registrar"
                    />
                    <button
                        onClick={this.onClick}
                        type="button"
                        className="btn btn-danger">
                        Eliminar
                    </button>
                </form>
            </div>
        );
    }

}