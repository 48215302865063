import React, { Component } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from "moment";
import 'moment/locale/es';
import RentasService from './services/RentasService';
import {toast} from 'react-toastify';
import BigCalendarToolbar from './BigCalendarToolbar'
import SimpleModal from './Modal';
import KilometrajeService from './services/KilometrajeService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt as icon } from '@fortawesome/free-solid-svg-icons';
import ChoferService from '../choferes/services/ChoferService';

const localizer = momentLocalizer(moment) 

export default class RentaCalendario extends Component {

  constructor(props){
    super(props);
    this.rentasService = new RentasService();
    this.kilometrajeService = new KilometrajeService();
    this.url = process.env.PUBLIC_URL;
    
    if(!this.props.match.params.id_chofer){
      this.props.history.push(this.url+'/rentas');
    }
    this.setDate(new Date());
    this.state = {
      title: '',
      subtitle: '',
      events: [],
      show: false,
      date: this.now,
      dateSelected: this.now,
      rentas: [],
      renta: {},
      kilometrajes: [],
      kilometraje: {
          id: 0,
          km: 0
      },
      isUpdateKilometraje: false
    };
  }

  handleClose = () => {
    this.getRentasFromRemote(this.now);
    this.getKilometrajesFromRemote(this.now);
    this.setState({
      show: false
    });
  }

  handleShow = async date => {
    this.setState({
      dateSelected: date
    });
    let renta = this.state.rentas.find(renta => {
      if(!renta.fecha) return false;
      const parts = renta.fecha.split('-');
      const year = parts[0];
      const month = parts[1]-1;
      const day = parts[2];
      const fecha = new Date(year, month, day);
      fecha.setHours(0,0,0,0);
      return fecha.getTime() === date.getTime();
    });
    let kilometraje = this.state.kilometrajes.find(kilometraje => {
      if(!kilometraje.fecha) return false;
      const parts = kilometraje.fecha.split('-');
      const year = parts[0];
      const month = parts[1]-1;
      const day = parts[2];
      const fecha = new Date(year, month, day);
      fecha.setHours(0,0,0,0);
      return fecha.getTime() === date.getTime();
    }); 

    if(renta){
      renta.deuda = renta.deuda_total;
      this.setState({
        renta: renta
      });
    }else{
      this.setState({
        renta: {}
      });
    }

    if(kilometraje){
      this.setState({
        kilometraje: {
          id: kilometraje.id,
          km: kilometraje.kilometraje
        },
        isUpdateKilometraje: true
      });
    }else{
      this.setState({
        kilometraje: {
          id: 0,
          km: 0
        },
        isUpdateKilometraje: false
      });
    }
    this.setState({
      show: true
    });
  }

  componentDidMount(){
    this.getRentasFromRemote(this.now);
    this.getKilometrajesFromRemote(this.now);
    this.getInfoChofer();
  }

  getInfoChofer = async () => {
    let title = 'No se encontro el chofer';
    let subtitle = '';
    try{
      const response = await ChoferService.getChofer(this.props.match.params.id_chofer);
      const chofer = response.data;
      if(chofer.nombre){
        title = chofer.nombre;
        subtitle = 'Taxi: #'+chofer.id_taxi+' '+chofer.marca+' '+chofer.modelo;
      }
      console.log(response.data);
    }catch(error){
      console.log(error);      
    }
    this.setState({
      title: title,
      subtitle: subtitle
    });
  }

  render(){
    return (
      <div>
        <SimpleModal
          handleClose={this.handleClose}
          show={this.state.show}
          renta={this.state.renta}
          onSubmitRenta={this.onSubmitRenta}
          onSubmitKilometraje={this.onSubmitKilometraje}
          kilometraje={this.state.kilometraje}
          onChange={this.onChange}/>
        <div className="m-2 text-center">
          <h2>{this.state.title}</h2>
          <h3>{this.state.subtitle}</h3>
        </div>
        <BigCalendar
          localizer={localizer}
          events={this.state.events}
          views={['month']}
          popup
          startAccessor="start"
          endAccessor="end"
          onNavigate={this.onRangChange}
          components={{
            toolbar: BigCalendarToolbar,
            month: {
                dateHeader: this.CustomDateHeader
            }
          }}
        />
      </div>
    );
  }

  setDate = date => {
    this.now = date;
    this.now.setHours(0,0,0,0);
  }

  onRangChange = (date, _, action) => {
    if(action === 'NEXT' ||
        action === 'PREV' ||
        action === 'TODAY'){
      this.setDate(date);
      this.getRentasFromRemote(this.now);
      this.getKilometrajesFromRemote(this.now);
    }
  }

  getRentasFromRemote = async date => {
    try{
      const idChofer = this.props.match.params.id_chofer;
      const response = await this.rentasService.getRentasByMonthYearChofer(date, idChofer);      
      this.setState({
        date: date,
        rentas: response.data
      });      
    }catch(error){
      console.log(error);
    }
  }

  getKilometrajesFromRemote = async date => {
    try{
      const idChofer = this.props.match.params.id_chofer;
      const response = await this.kilometrajeService.getKilometrajesByMonthYearChofer(date, idChofer);
      this.setState({
        date: date,
        kilometrajes: response.data
      });
    }catch(error){
      console.log(error);      
    }
  }

  onChange = e => {
    const index = parseInt(e.target.id);
    if(e.target.name === 'kilometraje') {
      const kilometraje = {
        ...this.state.kilometraje,
        km: e.target.value
      }
      this.setState({
        kilometrajes: [
          ...this.state.kilometrajes.splice(0, index),
          kilometraje,
          ...this.state.kilometrajes.splice(index+1)
        ],
        kilometraje: kilometraje
      });
    }else{
      const propertie = e.target.name==='cuota'?'cuota_editable':e.target.name;
      const renta = {
        ...this.state.rentas[index],
        [propertie]: e.target.value 
      }
      this.setState({
        rentas: [
          ...this.state.rentas.splice(0, index),
          renta,
          ...this.state.rentas.splice(index+1)
        ],
        renta: renta
      });
    }
  }

  onSubmitRenta = async e => {
    e.preventDefault();
    try{
      const index = parseInt(e.target.id);
      const renta = this.state.rentas[index];
      const data = {
        cuota: renta?renta.cuota_editable:0,
        pago: renta?renta.pago:0
      }
      const salida = await this.rentasService.updateRenta(data, renta?renta.id:-1);
      const response = salida.data;
      if(response.error){
        toast.error(response.error);
      }else{
        toast.success('Renta modificada exitosamente');
        this.getRentasFromRemote(this.state.date);
      }
    }catch(error){
      console.log(error);   
    }
  }

  onSubmitKilometraje = async e => {
    e.preventDefault();
    try{
      let salida;
      if(this.state.isUpdateKilometraje){
        salida = await this.kilometrajeService.updateKilometraje(this.state.kilometraje);
      }else{
        salida = await this.kilometrajeService.createKilometraje(this.state.kilometraje, this.props.match.params.id_chofer, this.state.dateSelected);
      }
      const data = salida.data;
      if(data.error){
        toast.error(data.error);
      }else{
        if(this.state.isUpdateKilometraje){
          toast.success('Kilometraje modificado con éxito');
        }else{
          toast.success('Kilometraje creado con éxito');
        }
        this.getKilometrajesFromRemote(this.now);
      }
    }catch(error){
        console.log(error);
    }
  }

  CustomDateHeader = ({label, date, onDrillDown}) => {
    const renta = this.state.rentas.find((renta, index) => {
      if(!renta.fecha) return false;
      const parts = renta.fecha.split('-');
      const year = parts[0];
      const month = parts[1]-1;
      const day = parts[2];
      const fecha = new Date(year, month, day);
      fecha.setHours(0,0,0,0);
      renta.index = index;
      return fecha.getTime() === date.getTime();
    });
    const kilometraje = this.state.kilometrajes.find((kilometraje, index) => {
      if(!kilometraje.fecha) return false;
      const parts = kilometraje.fecha.split('-');
      const year = parts[0];
      const month = parts[1]-1;
      const day = parts[2];
      const fecha = new Date(year, month, day);
      fecha.setHours(0,0,0,0);
      kilometraje.index = index;
      return fecha.getTime() === date.getTime();
    });
    let componentRenta;
    if(renta){
      renta.deuda = renta.deuda_total;

      componentRenta = (
        <div>
          <span>{'Deuda: $'+renta.deuda}</span><br/>
          <span>{'Cuota: $'+renta.cuota_editable}</span><br/>
          <span>{'Pagado: $'+renta.pago}</span><br/>
          <span>{'Faltante: $'+renta.faltante}</span><br/>
        </div>
      );
    }else{
      componentRenta = (
        <div>
          <span>Deuda: N/A</span><br/>
          <span>Cuota: N/A</span><br/>
          <span>Pagado: N/A</span><br/>
          <span>Faltante: N/A</span><br/>
        </div>
      );
    }
    let componentKilometraje;
    if(kilometraje){
      componentKilometraje = (
        <div>
          <span>{'Kilometraje: '+kilometraje.kilometraje}</span>
        </div>
      );
    }else{
      componentKilometraje = (
        <div>
          <span>Kilometraje: 0</span>
        </div>
      );
    }
    
    let diaSalida = (
      <div></div>
    );
    if(this.state.rentas.length > 0){
      const rentaInicial = this.state.rentas[0];
      const partsInicial = rentaInicial.fecha.split('-');
      let year = partsInicial[0];
      let month = partsInicial[1]-1;
      let day = partsInicial[2];
      const fechaInicial = new Date(year, month, day);

      const rentaFinal = this.state.rentas[this.state.rentas.length-1];
      const partsFinal = rentaFinal.fecha.split('-');
      year = partsFinal[0];
      month = partsFinal[1]-1;
      day = partsFinal[2];
      const fechaFinal = new Date(year, month, day);

      if(date.getTime() >= fechaInicial.getTime() && date.getTime() <= fechaFinal.getTime()){
        diaSalida = (
          <div>
            <div id="renta-info">
              {componentRenta}
              {componentKilometraje}
            </div>
            <button
              className="btn btn-primary btn-sm"
              onClick={e => this.handleShow(date)}>
              <FontAwesomeIcon
                icon={icon}/>
            </button>
          </div>
        );
      }
    }
    return (
      <div className="text-center">
        <h3 href="#" onClick={onDrillDown}>
            {label}
        </h3>
        {diaSalida}
      </div>
    );
  }

}