import React from 'react';
import ConfiguracionService from "./services/ConfiguracionService";
import ConfiguracionTable from "./ConfiguracionTable";
import ConfiguracionFormCreate from "./ConfiguracionFormCreate";


class ConfiguracionIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {configuracion: [], myconfiguracion: []};
    }

    render() {
        const {configuracion, myconfiguracion} = this.state;
        return (
            <div className="container-fluid">
                <div className="row my-4">
                    <div className="col-8">
                        <ConfiguracionTable configuracion={configuracion}
                                            onGetConfiguracion={this.handleOnGetConfiguracion}/>
                    </div>
                    <div className="col-4">
                        <ConfiguracionFormCreate onUpdateConfiguracion={this.handleUpdateConfig}
                                                 myconfiguracion={myconfiguracion}/>
                    </div>
                </div>
            </div>

        );
    }

    handleOnGetConfiguracion = (configuracion) => {
        this.setState({
            myconfiguracion: configuracion
        });
        this.render();
    };


    handleUpdateConfig = (updatedConfig) => {

        this.setState(
            prevState => {
                // Find the user
                const indexToUpdate = prevState.configuracion.findIndex(config => config.id === updatedConfig.id);
                // Modify the user
                prevState.configuracion[indexToUpdate] = updatedConfig;
                // Return a new array with modified user
                return {
                    configuracion: [...prevState.configuracion],
                };
            });
        this.setState({
            myconfiguracion: []
        });
    };

    componentDidMount() {
        ConfiguracionService.getAllConfiguracion().then(response => {
            this.setState({configuracion: response.data});
        });
    }
}

export default ConfiguracionIndex;